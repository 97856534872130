import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AuthProvider } from "./contexts/authContext";
import { AppContent } from "./AppContent";
import { SnackbarProvider } from "notistack";
import { AppContentFullScreen } from "./AppContentFullScreen";
import { useLocation } from "react-router-dom";
import { fullscreenPathPrefix } from "./utils/core/routes";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#E98300",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#696969",
      contrastText: "#ffffff",
    },
    info: {
      main: "#0288d1",
      contrastText: "#000",
    },
    success: {
      main: "#0ae600",
      contrastText: "#ffffff",
    },
    error: {
      main: "#ff0000",
      contrastText: "#ffffff",
    },
  },
});

const queryClient = new QueryClient();

function App() {
  const { pathname } = useLocation();
  if (pathname.includes(fullscreenPathPrefix)) return <AppContentFullScreen />;
  return <AppContent />;
}

export default function AppContainer() {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AuthProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}
