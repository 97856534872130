import React from 'react';
import { ReactNode } from 'react';
import './content.scss';

interface Props {
  children: ReactNode;
}

export function Content({ children }: Props) {
  return (
    <main className={'content'}>
      <div className={'toolbar'} />
      {children}
    </main>
  );
}
