import { Grid, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Loader } from "../../components/core/Loader/Loader";
import { BoardOwnerInputs } from "../../types/app/boardOwner.types";
import { dictionary } from "../../utils/core/dictionary";
import http from "../../api/core/api";
import { apiUrl } from "../../api/core/apiUrl";
import { SelectBoxAutocompleteAsync } from "../../components/core/SelectBox/SelectBoxAutocompleteAsync";
import { useParams } from "react-router-dom";
import { Board } from "../../types/app/board.types";

interface Props {
  onSubmit: SubmitHandler<BoardOwnerInputs>;
  defaultValues?: BoardOwnerInputs;
  onBack: any;
  readOnly: boolean;
}

export function BoardOwnerForm({ onSubmit, defaultValues, onBack }: Props) {
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<BoardOwnerInputs>();
  const { parentId } = useParams();

  const [board, setBoard] = useState<Board | null>(null);

  const fetchBoard = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.BOARD_ITEM}?id=${parentId}`,
    });
    setBoard(response?.data);
  };

  useEffect(() => {
    fetchBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!parentId) return;
    reset({ ...defaultValues, boardId: Number(parentId) });
  }, [defaultValues, reset, parentId]);

  if (!board) return <Loader />;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={"Board"}
              value={board.name}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectBoxAutocompleteAsync
              isRequired
              label={"User id"}
              valueKey={"netId"}
              labelKey={"fullName"}
              controllerProps={{
                name: "userId",
                control: control,
                rules: {
                  required: "User id is required",
                },
              }}
              errors={errors}
            />
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button type="button" variant="outlined" onClick={onBack}>
                {dictionary.BACK}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {dictionary.SAVE}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
