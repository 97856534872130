import React from "react";
import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./layout/Layout";
import { routes } from "./utils/core/routes";

export function AppContent() {
  return (
    <>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Layout>
        <>
          <div style={{ marginTop: 70, padding: "0 15px" }}>
            <Routes>
              {routes.map(({ path, component }) => {
                return <Route key={path} path={path} element={component} />;
              })}
            </Routes>
          </div>
        </>
      </Layout>
    </>
  );
}
