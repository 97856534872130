import React, { useState } from "react";
import { NavMenu } from "./NavMenu/NavMenu";
import { SidebarMenu } from "./SidebarMenu/SidebarMenu";

export function Navigation() {
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <NavMenu open={open} toggleDrawer={toggleDrawer} />
      <SidebarMenu open={open} toggleDrawer={toggleDrawer} />
    </>
  );
}
