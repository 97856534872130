import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../../contexts/authContext";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { paths } from "../../../utils/core/routes";
import { dictionary } from "../../../utils/core/dictionary";

export function NavMenuProfile() {
  const { user, signOut } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    if (signOut) signOut();
    navigate(paths.LOGIN_PAGE);
  };

  return (
    <>
      <Typography
        variant="h6"
        noWrap
        component="div"
        textAlign={"right"}
        style={{ flexGrow: 1 }}
      >
        {user ? (
          <div>
            <Button
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {user?.name}
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleClose}>
                <IconButton
                  title="Logout"
                  aria-label="Logout"
                  size="small"
                  onClick={handleLogout}
                >
                  <LogoutIcon
                    fontSize="small"
                    style={{ marginRight: "20px" }}
                  />
                  {dictionary.LOG_OUT}
                </IconButton>
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <Link
            to={`${paths.LOGIN_PAGE}`}
            style={{ color: theme.palette.primary.main, fontWeight: 600 }}
          >
            {dictionary.LOG_IN}
          </Link>
        )}
      </Typography>
    </>
  );
}
