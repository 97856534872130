import { api } from "./api";
import { AppUser } from "../../types/core/auth.types";

export const getAuthorize = () =>
  api.get<boolean>("/User/Authorize").then((res) => res.data);

export const getCurrentUser = () =>
  api.get<AppUser>("/User/GetCurrentUser").then((res) => res.data);

export const getUserSearch = (name: string) =>
  api.get<AppUser[]>(`/User/SearchByName?name=${name}`).then((res) => res.data);

export const getUserByNetId = (netId: string) =>
  api
    .get<AppUser>(`/User/SearchByNetId?netId=${netId}`)
    .then((res) => res.data);
