export const dictionary = {
  // General
  CLOSE: "Close",
  CONFIRM: "Confirm",
  CONFIRM_MESSAGE: "Are you sure you want to perform this action?",
  EDIT: "Edit",
  DOWNLOAD: "Download",
  NEW: "New",
  BACK: "Back",
  SAVE: "Save",
  DELETE: "Delete",
  ADD: "Add",
  DELETE_MESSAGE: "Are you sure you want to delete record?",
  SHOW: "Show",
  DETAIL: "Detail",
  APPROVE: "Approve",
  APPROVE_SELECTED: "Approve selected",
  LOG_IN: "Log in",
  LOG_OUT: "Log out",
  LOGIN_FAILED: "Login failed",
  UNIT_PX: "px",

  // Permissions
  PERMISSIONS: "Permissions",
  PERMISSION_CREATE: "Permission create",
  PERMISSION_CREATED: "Permission created",
  PERMISSION_DELETED: "Permission deleted",
  // Boards
  BOARD: "Board",
  BOARDS: "Boards",
  BOARD_CREATE: "Board create",
  BOARD_DETAIL: "Board detail",
  BOARD_EDIT: "Board edit",
  BOARD_CREATED: "Board created",
  BOARD_EDITED: "Board edited",
  BOARD_DELETED: "Board deleted",
  // Board content
  BOARD_CONTENT: "Board content",
  BOARD_CONTENT_CREATE: "Board content create",
  BOARD_CONTENT_DETAIL: "Board content detail",
  BOARD_CONTENT_CREATED: "Board content created",
  BOARD_CONTENT_EDITED: "Board content edited",
  BOARD_CONTENT_DELETED: "Board content deleted",
  // Board content attachment
  BOARD_CONTENT_ATTACHMENT_UPLOADED: "Board content attachment uploaded",
  BOARD_CONTENT_FILE_ATTACHMENT_DELETED: "Attachment dleeted",
  UPLOAD: "Upload",
  // Board owner
  BOARD_OWNER: "Board owner",
  BOARD_OWNERS: "Board owners",
  BOARD_OWNER_CREATE: "Board owner create",
  BOARD_OWNER_DETAIL: "Board owner detail",
  BOARD_OWNER_CREATED: "Board owner created",
  BOARD_OWNER_EDITED: "Board owner edited",
  BOARD_OWNER_DELETED: "Board owner deleted",
  // Hierarchy
  HIERARCHY: "Hierarchy",
  HIERARCHY_CREATE: "Hierarchy create",
  HIERARCHY_EDIT: "Hierarchy detail",
  HIERARCHY_CREATED: "Hierarchy created",
  HIERARCHY_EDITED: "Hierarchy edited",
  HIERARCHY_DELETED: "Hierarchy deleted",
  // Local user
  LOCAL_USER: "User",
  LOCAL_USERS: "Users",
  LOCAL_USER_CREATE: "Create user",
  LOCAL_USER_EDIT: "Edit user",
  LOCAL_USER_DETAIL: "User detail",
  LOCAL_USER_DELETED: "User deleted",
  LOCAL_USER_CREATED: "User created",
  LOCAL_USER_EDITED: "User edited",
  CHANGE_PASSWORD: "Change password",
  LOCAL_USER_PASSWORD_CHANGED: "Password changed succesfully",
  FUNCTION_IS_DISABLED: "Function is disabled",
  PASSWORDS_DO_NOT_MATCH: "Passwords do not match",
  LOCAL_USER_ID: "User id",
  LOCAL_USER_NAME: "Name",
  LOCAL_USER_EMAIL: "Email",
  LOCAL_USER_PASSWORD: "Password",
  LOCAL_USER_PASSWORD2: "Repeat password",
  ID_IS_REQUIRED: "Id is required",
  NAME_IS_REQUIRED: "Name is required",
};
