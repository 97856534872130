import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { Drawer, DrawerHeader } from "./SidebarMenu.styles";
import { navigation } from "../../../utils/core/navigation";
import { apiUrl } from "../../../api/core/apiUrl";
import { paths } from "../../../utils/core/routes";
import http from "../../../api/core/api";

interface Props {
  open: boolean;
  toggleDrawer: () => void;
}

export function SidebarMenu({ open, toggleDrawer }: Props) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [featureIsEnabled, setFeatureIsEnabled] = useState<boolean>();

  const fetchFeatureIsEnabled = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.LOCAL_USER_FEATURE_ENABLED}`,
    });
    setFeatureIsEnabled(response?.data);
  };

  useEffect(() => {
    fetchFeatureIsEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <img
          src={"/logo/logo-toolbar.svg"}
          alt={"Application logo"}
          title={"Application logo"}
          style={{ width: "50%" }}
        />
        <IconButton onClick={toggleDrawer}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {navigation.map(({ title, path, icon, items }, index) => {
          // skrýt položku Uživatelé, když featureIsEnabled = false
          if (!featureIsEnabled && path === paths.LOCAL_USER) return null;
          return (
            <SidebarMenuItem
              id={index}
              key={index}
              name={title}
              location={path}
              icon={icon}
              items={items}
              handleClick={handleClick}
              open={isOpen}
            />
          );
        })}
      </List>
    </Drawer>
  );
}
