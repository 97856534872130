import { Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { apiUrl } from "../../api/core/apiUrl";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { PermissionInputs } from "../../types/app/permission.types";
import { dictionary } from "../../utils/core/dictionary";
import { PermissionForm } from "./PermissionForm";
import http from "../../api/core/api";

export function PermissionCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const defaultValues: PermissionInputs = {
    userId: null,
    identifier: null,
  };

  const convertDataFromInputToRequest = (data: PermissionInputs) => ({
    userId: data?.userId?.id ? data.userId.id : null,
    identifier: data.identifier ? data.identifier : null,
  });

  const handleOnSubmit: SubmitHandler<PermissionInputs> = async (
    values: PermissionInputs
  ) => {
    try {
      let data = convertDataFromInputToRequest(values);
      const response = await http.apiPost({
        url: apiUrl.PERMISSION_POST,
        data: data,
      });
      if (response?.status === 201) {
        enqueueSnackbar(dictionary.PERMISSION_CREATED, {
          variant: "success",
        });
        navigate(`/permission`);
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  return (
    <>
      <PageHeader title={dictionary.PERMISSION_CREATE} />
      <PageContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <Paper>
              <PermissionForm
                onSubmit={handleOnSubmit}
                onBack={() => navigate("/permission")}
                defaultValues={defaultValues}
              />
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
