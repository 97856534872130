import { Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { apiUrl } from "../../api/core/apiUrl";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { BoardOwnerInputs } from "../../types/app/boardOwner.types";
import { dictionary } from "../../utils/core/dictionary";
import { BoardOwnerForm } from "./BoardOwnerForm";
import http from "../../api/core/api";
import { paths } from "../../utils/core/routes";
import { useParams } from "react-router-dom";

export function BoardOwnerCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { parentId } = useParams();

  // TODO - kdo může vytvářet boardOwnery?
  const readOnly = false;
  const convertDataFromInputToRequest = (data: BoardOwnerInputs) => ({
    boardId: data.boardId,
    userId: data.userId.id,
  });

  const handleOnSubmit: SubmitHandler<BoardOwnerInputs> = async (
    values: BoardOwnerInputs
  ) => {
    try {
      let data = convertDataFromInputToRequest(values);
      const response = await http.apiPost({
        url: apiUrl.BOARD_OWNER_POST,
        data: data,
      });
      if (response?.status === 201) {
        enqueueSnackbar(dictionary.BOARD_OWNER_CREATED, {
          variant: "success",
        });
        navigate(`${paths.BOARD_EDIT}/${Number(parentId)}`);
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  return (
    <>
      <PageHeader title={dictionary.BOARD_OWNER_CREATE} />
      <PageContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <Paper>
              <BoardOwnerForm
                onSubmit={handleOnSubmit}
                onBack={() =>
                  navigate(`${paths.BOARD_EDIT}/${Number(parentId)}`)
                }
                readOnly={readOnly}
              />
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
