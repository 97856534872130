import { Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { LocalUserInputs } from "../../types/app/localUser.types";
import { convertDataFromInputToCreateRequest } from "../../utils/core/functions/localUser.functions";
import { LocalUserForm } from "./LocalUserForm";
import http from "../../api/core/api";
import { dictionary } from "../../utils/core/dictionary";
import { paths } from "../../utils/core/routes";
import { apiUrl } from "../../api/core/apiUrl";

export function LocalUserCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const defaultValues: LocalUserInputs = {
    id: "",
    email: "",
    name: "",
    password: "",
  };

  const handleOnSubmit: SubmitHandler<LocalUserInputs> = async (
    values: LocalUserInputs
  ) => {
    try {
      let data = convertDataFromInputToCreateRequest(values);
      const response = await http.apiPost({
        url:
          `${apiUrl.LOCAL_USER_POST}?id=${data.id}&name=${data.name}` +
          (data.email ? `&email=${data.email}` : "") +
          `&password=${encodeURIComponent(data.password)}`,
        data: { ...data, password: encodeURIComponent(data.password) },
      });
      if (response?.status === 204) {
        enqueueSnackbar(dictionary.LOCAL_USER_CREATED, {
          variant: "success",
        });
        navigate(`${paths.LOCAL_USER}`);
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  return (
    <>
      <PageHeader title={dictionary.LOCAL_USER_CREATE} />
      <PageContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8} lg={4}>
            <Paper>
              <LocalUserForm
                onSubmit={handleOnSubmit}
                onBack={() => navigate("/localUser")}
                defaultValues={defaultValues}
              />
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
