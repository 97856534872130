import React, { useEffect, useRef, useState } from "react";
import http from "../../api/core/api";
import { apiUrl } from "../../api/core/apiUrl";
import { localStorageKeys } from "../../utils/keys/localStorageKeys";

interface Props {
  path: string;
  fileName: string;
  attachmentHeight?: number;
}

export function BoardContentCardFilePreview({
  path,
  fileName,
  attachmentHeight,
}: Props) {
  const [file, setFile] = useState();

  useEffect(() => {
    getFilePreview(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authHeaders = () => {
    const token = localStorage.getItem(localStorageKeys.BEARER_TOKEN_KEY);
    if (token) {
      return {
        Authorization: `Bearer ${JSON.parse(token)}`,
      };
    }
    return null;
  };

  const getFilePreview = async (path: string) => {
    await http
      .apiGetFiles({
        url: `${apiUrl.BOARD_CONTENT_NETWORK_FILE}?path=${path}`,
        config: {
          headers: {
            ...authHeaders(),
            "Content-Type": "application/json",
          },
          method: "GET",
          responseType: "blob",
        },
      })
      .then((response) => setFile(response?.data));
  };

  const imagePlaceholder = `/img/placeholder-image.png`;
  // Reference na obrázek - aby bylo možné po načtení z API přiřadit blob image elementu
  const imageRef = useRef({} as any);

  useEffect(() => {
    if (!file) return;
    imageRef.current!.src = URL.createObjectURL(file);
  }, [file]);

  if (!file) return <>Loading</>;

  return (
    <div style={{ textAlign: "center" }}>
      <img
        ref={imageRef}
        src={imagePlaceholder}
        className="figure-img img-fluid rounded"
        style={{
          width: "auto",
          minHeight: attachmentHeight ? undefined : "80vh",
          maxHeight: attachmentHeight ? attachmentHeight + "px" : undefined,
          marginBottom: 0,
        }}
        alt={fileName}
        title={fileName}
      />
    </div>
  );
}
