import {
  LocalUserCreateRequest,
  LocalUserInputs,
  LocalUserPasswordInputs,
  LocalUserUpdatePasswordRequest,
  LocalUserUpdateRequest,
} from '../../../types/app/localUser.types';

export const convertDataFromInputToCreateRequest = (
  data: LocalUserInputs,
): LocalUserCreateRequest => ({
  id: data.id,
  name: data.name,
  email: data.email,
  password: data.password,
});

export const convertDataFromInputToUpdateRequest = (
  data: LocalUserInputs,
): LocalUserUpdateRequest => ({
  id: data.id,
  name: data.name,
  email: data.email,
});

export const convertPassDataFromInputToUpdateRequest = (
  data: LocalUserPasswordInputs,
): LocalUserUpdatePasswordRequest => ({
  id: data.id,
  password: data.password,
});
