import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface DialogProps {
  open: boolean;
  title: string;
  maxWidth: false | "xs" | "lg" | "xl" | undefined;
  children: any;
  onClose: () => void;
}

export function CustomDialog({
  open,

  title,
  maxWidth,
  children,
  onClose,
}: DialogProps) {
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      onClose={() => {
        onClose();
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        size={"large"}
        sx={{
          position: "absolute",
          right: 15,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box px={2} py={1}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box py={1}>{children}</Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
