import { Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { apiUrl } from "../../api/core/apiUrl";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { dictionary } from "../../utils/core/dictionary";
import http from "../../api/core/api";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BoardContentForm } from "./BoardContentForm";
import { paths } from "../../utils/core/routes";
import { BoardContentInputs } from "../../types/app/boardContent.types";
import { Loader } from "../../components/core/Loader/Loader";
import { BoardContentFileUploadForm } from "./BoardContentFIleUpload";

export function BoardContentEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [data, setData] = useState<BoardContentInputs | null>(null);
  const [attachmentId, setAttachmentId] = useState<number | null>(null);

  const fetchBoardContent = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.BOARD_CONTENT_ITEM}?id=${id}`,
    });
    setData(response?.data);
  };
  const fetchBoardAttachments = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.BOARD_CONTENT_FILE_ATTACHMENT_LIST}?parentId=${id}`,
    });
    setAttachmentId(response && response.data[0] ? response?.data[0].id : null);
  };

  useEffect(() => {
    fetchBoardContent();
    fetchBoardAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertDataFromInputToRequest = (data: BoardContentInputs) => ({
    id: data.id,
    boardId: data.boardId,
    headline: data.headline,
    positionRow: data.positionRow,
    positionColumn: data.positionColumn,
    urlPreview: data.urlPreview,
    urlDetail: data.urlDetail,
    urlPreviewWidth: data.urlPreviewWidth,
    urlPreviewHeight: data.urlPreviewHeight,
    urlPreviewZoom: data.urlPreviewZoom,
  });

  const handleOnSubmit: SubmitHandler<BoardContentInputs> = async (
    values: BoardContentInputs
  ) => {
    try {
      let data = convertDataFromInputToRequest(values);
      const response = await http.apiPut({
        url: apiUrl.BOARD_CONTENT_PUT,
        data: data,
      });
      if (response?.status === 200) {
        enqueueSnackbar(dictionary.BOARD_CONTENT_EDITED, {
          variant: "success",
        });
        navigate(`${paths.BOARD_DETAIL}/${state.boardId}`);
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  if (!data) return <Loader />;
  return (
    <>
      <PageHeader title={dictionary.BOARD_CONTENT_DETAIL} />
      <PageContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7} lg={7}>
            <Paper>
              <BoardContentForm
                onSubmit={handleOnSubmit}
                onBack={() =>
                  navigate(`${paths.BOARD_DETAIL}/${state.boardId}`)
                }
                defaultValues={data}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <BoardContentFileUploadForm
              attachmentId={attachmentId}
              fetchBoardAttachments={fetchBoardAttachments}
            />
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
