import { Grid, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Loader } from "../../components/core/Loader/Loader";
import { BoardInputs } from "../../types/app/board.types";
import { dictionary } from "../../utils/core/dictionary";
import http from "../../api/core/api";
import { apiUrl } from "../../api/core/apiUrl";
import { SelectBox } from "../../components/core/SelectBox/SelectBox";
import { Hierarchy } from "../../types/app/hierarchy.types";

interface Props {
  onSubmit: SubmitHandler<BoardInputs>;
  defaultValues?: BoardInputs;
  onBack: any;
  readOnly: boolean;
}

export function BoardForm({
  onSubmit,
  defaultValues,
  onBack,
  readOnly,
}: Props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<BoardInputs>();

  const [hierarchyList, setHierarchyList] = useState<Hierarchy[] | null>();
  const fetchHierarchy = async () => {
    const response = await http.apiGet({ url: apiUrl.HIERARCHY_LIST });
    setHierarchyList(response?.data);
  };

  useEffect(() => {
    fetchHierarchy();
  }, []);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  if (!hierarchyList) return <Loader />;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={3}>
          {/* hierarchy */}
          <Grid item xs={12}>
            <SelectBox
              label={"Hierarchy"}
              items={hierarchyList}
              valueKey={"id"}
              labelKey={"name"}
              // onChange={(val) => handleSelectApp(val)}
              controllerProps={{
                name: "hierarchyId",
                control: control,
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={"Name"}
              {...register("name")}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={"Headline"}
              {...register("headline")}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type={"number"}
              label={"Rows"}
              {...register("rows")}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type={"number"}
              label={"Columns"}
              {...register("cols")}
              disabled={readOnly}
            />
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button type="button" variant="outlined" onClick={onBack}>
                {dictionary.BACK}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {dictionary.SAVE}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
