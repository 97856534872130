import AddIcon from "@mui/icons-material/Add";
import { Fab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Loader } from "../../components/core/Loader/Loader";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { UserRoles } from "../../types/core/auth.types";
import { dictionary } from "../../utils/core/dictionary";
import { LocalUserList } from "./LocalUserList";
import http from "../../api/core/api";
import { useAuth } from "../../contexts/authContext";
import { apiUrl } from "../../api/core/apiUrl";

export function LocalUserPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const isReadOnly = user?.roles.includes(UserRoles.Admin) ? false : true;
  const [featureIsEnabled, setFeatureIsEnabled] = useState<boolean>();

  const fetchFeatureIsEnabled = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.BOARD_CONTENT_LIST}`,
    });
    setFeatureIsEnabled(response?.data);
  };

  useEffect(() => {
    fetchFeatureIsEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerButtons = [
    <Fab
      key={0}
      color="primary"
      aria-label="add"
      size="medium"
      onClick={() => {
        navigate(`/localUser/create`);
      }}
    >
      <AddIcon />
    </Fab>,
  ];

  if (featureIsEnabled === undefined) return <Loader />;
  return (
    <>
      <PageHeader
        title={dictionary.LOCAL_USERS}
        buttons={isReadOnly ? null : headerButtons}
        xs={6}
      />

      {featureIsEnabled && (
        <PageContent>
          <LocalUserList isReadOnly={isReadOnly} />
        </PageContent>
      )}
      {!featureIsEnabled && (
        <PageContent>
          <Typography>{dictionary.FUNCTION_IS_DISABLED}</Typography>
        </PageContent>
      )}
    </>
  );
}
