import { Grid, InputAdornment, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { dictionary } from "../../utils/core/dictionary";
import { BoardContentInputs } from "../../types/app/boardContent.types";

interface Props {
  onSubmit: SubmitHandler<BoardContentInputs>;
  defaultValues: BoardContentInputs | null;
  onBack: any;
}

export function BoardContentForm({ onSubmit, defaultValues, onBack }: Props) {
  const { register, handleSubmit, reset } = useForm<BoardContentInputs>();

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label={"Headline"}
              variant="outlined"
              {...register("headline")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label={"Url detail"}
              variant="outlined"
              {...register("urlDetail")}
              helperText="Odkaz nesmí obsahovat mezery"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label={"Url preview"}
              variant="outlined"
              {...register("urlPreview")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="number"
              label={"Url preview width"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {dictionary.UNIT_PX}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              {...register("urlPreviewWidth")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="number"
              label={"Url preview height"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {dictionary.UNIT_PX}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              {...register("urlPreviewHeight")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="number"
              label={"Url preview zoom"}
              variant="outlined"
              {...register("urlPreviewZoom")}
            />
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button type="button" variant="outlined" onClick={onBack}>
                {dictionary.BACK}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {dictionary.SAVE}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
