import { Typography } from "@mui/material";
import { theme } from "../../App";
import { PageContent } from "../../components/core/PageContent/PageContent";

export function HomePage() {
  return (
    <PageContent>
      <Typography
        variant={"h4"}
        align="center"
        color={theme.palette.primary.main}
      >
        Welcome to GO/PIM Meeting
      </Typography>
    </PageContent>
  );
}
