import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Paper, TableContainer, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertDialog } from "../../components/core/Dialogs/AlertDialog";
import http from "../../api/core/api";
import { apiUrl } from "../../api/core/apiUrl";
import DataGridTable from "../../components/core/DataGridTable/DataGridTable";
import { Loader } from "../../components/core/Loader/Loader";
import { useDeleteDialog } from "../../hooks/useDeleteDialog";
import { dictionary } from "../../utils/core/dictionary";
import { BoardOwner } from "../../types/app/boardOwner.types";
import { paths } from "../../utils/core/routes";
import { useParams } from "react-router-dom";

export function BoardOwnerList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    isOpen: isDeleteDialogOpen,
    idToDelete,
    setDeleteAttempt,
    cancelDeleteAttempt,
    confirmDeleteAttempt,
  } = useDeleteDialog();

  //TODO
  const isReadOnly = false;
  const [boardOwnerList, setBoardOwnerList] = useState<BoardOwner[] | null>();

  const fetchBoardOwners = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.BOARD_OWNER_LIST}?parentId=${id}`,
    });
    setBoardOwnerList(response?.data);
  };

  useEffect(() => {
    fetchBoardOwners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRemoveItem = async (idToDelete: number | null) => {
    if (idToDelete === null) return;
    const response = await http.apiDelete({
      url: `${apiUrl.BOARD_OWNER_DELETE}?id=${idToDelete}`,
    });
    if (response?.status === 204) {
      enqueueSnackbar(dictionary.BOARD_OWNER_DELETED, {
        variant: "success",
      });
      confirmDeleteAttempt();
      fetchBoardOwners();
    }
  };

  const columns = [
    {
      headerName: "Name",
      field: "userName",
      width: 200,
    },
    {
      headerName: "User id",
      field: "userId",
      width: 200,
    },
    {
      field: " ",
      sortable: false,
      disableColumnMenu: true,

      flex: 1,
      align: "right",
      minWidth: 100,
      renderCell: (params: any) => {
        return (
          <>
            {!isReadOnly && (
              <Tooltip title={dictionary.DELETE}>
                <Button
                  style={{ minWidth: 30 }}
                  onClick={() => handleOnDelete(params.id)}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];
  const handleOnCreate = () =>
    navigate(`${paths.BOARD_DETAIL}/${id}${paths.BOARD_OWNER_CREATE}`);
  const handleOnDelete = (id: number) => setDeleteAttempt(id);

  const handleConfirm = () => {
    if (!idToDelete) return;

    onRemoveItem(idToDelete);
  };
  if (!boardOwnerList) return <Loader />;
  return (
    <>
      <TableContainer component={Paper}>
        <DataGridTable
          height={"70vh"}
          data={boardOwnerList}
          columns={columns}
          fulltextSearch={true}
          autoPageSize={true}
        />
        <Tooltip title={dictionary.NEW}>
          <Button onClick={handleOnCreate}>
            <AddIcon />
          </Button>
        </Tooltip>
      </TableContainer>
      <AlertDialog
        open={isDeleteDialogOpen}
        title={dictionary.DELETE}
        content={dictionary.DELETE_MESSAGE}
        handleClose={cancelDeleteAttempt}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
