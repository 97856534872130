import AddIcon from "@mui/icons-material/Add";
import { Fab } from "@mui/material";
import { useNavigate } from "react-router";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { useAuth } from "../../contexts/authContext";
import { UserRoles } from "../../types/core/auth.types";
import { dictionary } from "../../utils/core/dictionary";
import { BoardList } from "./BoardList";

export function BoardPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const isReadOnly = user
    ? user.roles.indexOf(UserRoles.Admin) > -1
      ? false
      : true
    : true;
  const headerButtons = [
    <Fab
      key={0}
      color="primary"
      aria-label="add"
      size="medium"
      onClick={() => {
        navigate(`/board/create`);
      }}
    >
      <AddIcon />
    </Fab>,
  ];

  return (
    <>
      <PageHeader
        title={dictionary.BOARDS}
        buttons={isReadOnly ? null : headerButtons}
        xs={6}
      />
      <PageContent>
        <BoardList isReadOnly={isReadOnly} />
      </PageContent>
    </>
  );
}
