import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

function escapeRegExp(value: any) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function checkColumns(field: any, data: any) {
  return data.columns.some((item: any) => field === item.field);
}
function QuickSearchToolbar(props: any) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      }}
    >
      <div></div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "divider",
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function DataGridTable(data: any) {
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState<number>();
  const [rows, setRows] = useState(data.data);

  const requestSearch = (searchValue: any) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = data.data.filter((row: any) => {
      return Object.keys(row).some((field) => {
        if (checkColumns(field, data)) {
          if (typeof row[field] !== "object") {
            return searchRegex.test(row[field].toString());
          } else {
            return searchRegex.test(moment(row[field]).format(""));
          }
        } else {
          return null;
        }
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(data.data);
    if (
      data.autoPageSize === true &&
      data.pageSize &&
      data.rowsPerPageOptions
    ) {
      data.pageSize = undefined;
      data.rowsPerPageOptions = undefined;
    }
  }, [data, data.data]);

  return (
    <>
      <Box sx={{ height: data.height, width: 1 }}>
        {data && !data.fulltextSearch && (
          <DataGrid
            rows={rows}
            columns={data.columns}
            disableSelectionOnClick
            pageSize={pageSize}
            rowsPerPageOptions={[
              data.rowsPerPageOptions ? data.rowsPerPageOptions : 10,
            ]}
            density={data.density ? data.density : "compact"}
            onPageSizeChange={(newPageSize) => {
              if (newPageSize === 0) {
                setPageSize(1);
              } else {
                setPageSize(newPageSize);
              }
            }}
            autoPageSize={data.autoPageSize}
          />
        )}
        {data && data.fulltextSearch && (
          <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            rows={rows}
            columns={data.columns}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event: any) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
              },
            }}
            disableSelectionOnClick
            pageSize={pageSize}
            rowsPerPageOptions={[
              data.rowsPerPageOptions ? data.rowsPerPageOptions : 10,
            ]}
            density={data.density ? data.density : "compact"}
            onPageSizeChange={(newPageSize) => {
              if (newPageSize === 0) {
                setPageSize(1);
              } else {
                setPageSize(newPageSize);
              }
            }}
            autoPageSize={data.autoPageSize}
          />
        )}
      </Box>
    </>
  );
}
