import { Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { apiUrl } from "../../api/core/apiUrl";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { Board, BoardInputs } from "../../types/app/board.types";
import { dictionary } from "../../utils/core/dictionary";
import { BoardForm } from "./BoardForm";
import http from "../../api/core/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/core/Loader/Loader";
import { BoardOwnerList } from "../BoardOwner/BoardOwnerList";
import { useAuth } from "../../contexts/authContext";
import { UserRoles } from "../../types/core/auth.types";

export function BoardEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  // jen admin může editovat board
  const readOnly = user
    ? user.roles.indexOf(UserRoles.Admin) > -1
      ? false
      : true
    : true;

  const [defaultValues, setDefaultValues] = useState<BoardInputs | null>(null);
  const [data, setData] = useState<Board | null>(null);

  const fetchBoard = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.BOARD_ITEM}?id=${id}`,
    });
    setData(response?.data);
  };

  useEffect(() => {
    fetchBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data) return;

    setDefaultValues({
      id: data.id,
      headline: data.headline,
      hierarchyId: data.hierarchyId,
      name: data.name,
      rows: data.rows,
      cols: data.cols,
    });
  }, [data]);

  const convertDataFromInputToRequest = (data: BoardInputs) => ({
    id: data.id,
    headline: data.headline,
    hierarchyId: data.hierarchyId,
    name: data.name,
    rows: data.rows,
    cols: data.cols,
  });

  const handleOnSubmit: SubmitHandler<BoardInputs> = async (
    values: BoardInputs
  ) => {
    try {
      let data = convertDataFromInputToRequest(values);
      const response = await http.apiPut({
        url: apiUrl.BOARD_PUT,
        data: data,
      });
      if (response?.status === 200) {
        enqueueSnackbar(dictionary.BOARD_EDITED, {
          variant: "success",
        });
        navigate(`/boards`);
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  if (!defaultValues) return <Loader />;
  return (
    <>
      <PageHeader title={dictionary.BOARD_EDIT} />
      <PageContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <Paper>
              <BoardForm
                onSubmit={handleOnSubmit}
                onBack={() => navigate("/boards")}
                defaultValues={defaultValues}
                readOnly={readOnly}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={9}>
            <BoardOwnerList />
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
