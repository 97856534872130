import { Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { apiUrl } from "../../api/core/apiUrl";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { HierarchyInputs } from "../../types/app/hierarchy.types";
import { dictionary } from "../../utils/core/dictionary";
import { HierarchyForm } from "./HierarchyForm";
import http from "../../api/core/api";
import { useAuth } from "../../contexts/authContext";
import { UserRoles } from "../../types/core/auth.types";
import { useLocation } from "react-router-dom";
import { paths } from "../../utils/core/routes";

export function HierarchyCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = useAuth();
  // jen admin může editovat hierarchy
  const readOnly = user
    ? user.roles.indexOf(UserRoles.Admin) > -1
      ? false
      : true
    : true;

  const convertDataFromInputToRequest = (data: HierarchyInputs) => ({
    id: data.id,
    parentId: data.parentId === null ? undefined : data.parentId,
    name: data.name,
  });

  const handleOnSubmit: SubmitHandler<HierarchyInputs> = async (
    values: HierarchyInputs
  ) => {
    try {
      let data = convertDataFromInputToRequest(values);
      const response = await http.apiPost({
        url: apiUrl.HIERARCHY_POST,
        data: data,
      });
      if (response?.status === 201) {
        enqueueSnackbar(dictionary.HIERARCHY_CREATED, {
          variant: "success",
        });
        navigate(
          state ? `${paths.HIERARCHY_EDIT}/${state}` : `${paths.HIERARCHY}`
        );
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };
  return (
    <>
      <PageHeader title={dictionary.HIERARCHY_CREATE} />
      <PageContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <Paper>
              <HierarchyForm
                onSubmit={handleOnSubmit}
                onBack={() =>
                  navigate(
                    state
                      ? `${paths.HIERARCHY_EDIT}/${state}`
                      : `${paths.HIERARCHY}`
                  )
                }
                readOnly={readOnly}
                defaultValues={{
                  id: undefined,
                  name: undefined,
                  parentId: state,
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
