import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Paper, TableContainer, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertDialog } from "../../components/core/Dialogs/AlertDialog";
import http from "../../api/core/api";
import { apiUrl } from "../../api/core/apiUrl";
import DataGridTable from "../../components/core/DataGridTable/DataGridTable";
import { Loader } from "../../components/core/Loader/Loader";
import { useDeleteDialog } from "../../hooks/useDeleteDialog";
import { dictionary } from "../../utils/core/dictionary";
import { Board } from "../../types/app/board.types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

interface Props {
  isReadOnly: boolean;
}
export function BoardList({ isReadOnly }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    isOpen: isDeleteDialogOpen,
    idToDelete,
    setDeleteAttempt,
    cancelDeleteAttempt,
    confirmDeleteAttempt,
  } = useDeleteDialog();

  const [boardList, setBoardList] = useState<Board[] | null>();

  const fetchBoards = async () => {
    const response = await http.apiGet({ url: apiUrl.BOARD_LIST });
    setBoardList(response?.data);
  };

  useEffect(() => {
    fetchBoards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRemoveItem = async (idToDelete: number | null) => {
    if (idToDelete === null) return;
    const response = await http.apiDelete({
      url: `${apiUrl.BOARD_DELETE}?id=${idToDelete}`,
    });
    if (response?.status === 204) {
      enqueueSnackbar(dictionary.BOARD_DELETED, {
        variant: "success",
      });
      confirmDeleteAttempt();
      fetchBoards();
    }
  };

  const columns = [
    {
      headerName: "Hierarchy",
      field: "hierarchyName",
      width: 200,
    },
    {
      headerName: "Name",
      field: "name",
      width: 200,
    },
    {
      headerName: "Headline",
      field: "headline",
      width: 200,
    },
    {
      field: " ",
      sortable: false,
      disableColumnMenu: true,

      flex: 1,
      minWidth: 100,
      align: "right",
      renderCell: (params: any) => {
        return (
          <>
            <Tooltip title={"Detail"}>
              <Button
                style={{ minWidth: 30 }}
                onClick={() => handleOnDetail(params.id)}
              >
                <VisibilityIcon />
              </Button>
            </Tooltip>
            {!isReadOnly && (
              <>
                <Tooltip title={"Edit"}>
                  <Button
                    style={{ minWidth: 30 }}
                    onClick={() => handleOnEdit(params.id)}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={dictionary.DELETE}>
                  <Button
                    style={{ minWidth: 30 }}
                    onClick={() => handleOnDelete(params.id)}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
          </>
        );
      },
    },
  ];
  const handleOnCreate = () => navigate(`/board/create`);
  const handleOnEdit = (id: number) => navigate(`/board/edit/${id}`);
  const handleOnDetail = (id: number) => navigate(`/board/detail/${id}`);
  const handleOnDelete = (id: number) => setDeleteAttempt(id);

  const handleConfirm = () => {
    if (!idToDelete) return;

    onRemoveItem(idToDelete);
  };

  if (!boardList) return <Loader />;
  return (
    <>
      <TableContainer component={Paper}>
        <DataGridTable
          height={"70vh"}
          data={boardList}
          columns={columns}
          fulltextSearch={true}
          autoPageSize={true}
        />
        {!isReadOnly && (
          <Tooltip title={dictionary.NEW}>
            <Button onClick={handleOnCreate}>
              <AddIcon />
            </Button>
          </Tooltip>
        )}
      </TableContainer>
      <AlertDialog
        open={isDeleteDialogOpen}
        title={dictionary.DELETE}
        content={dictionary.DELETE_MESSAGE}
        handleClose={cancelDeleteAttempt}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
