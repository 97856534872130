export const apiUrl = {
  // User
  LOGIN: "User/LogIn",
  GERNERATE_TOKEN_CURR_USER: "User/GenerateTokenForCurrentUser",
  GET_CURRENT_USER: "User/GetCurrentUser",
  // Permissions
  PERMISSION_POST: "Permission/CreateItem",
  PERMISSION_PUT: "Permission/UpdateItem",
  PERMISSION_DELETE: "Permission/DeleteItem",
  PERMISSION_LIST: "Permission/GetList",
  PERMISSION_ITEM: "Permission/GetItem",
  // Role
  ROLE_LIST: "Role/GetList",
  // App info
  APP_INFO: "Common/AppInfo",
  // Board
  BOARD_POST: "Board/CreateItem",
  BOARD_PUT: "Board/UpdateItem",
  BOARD_DELETE: "Board/DeleteItem",
  BOARD_LIST: "Board/GetList",
  BOARD_ITEM: "Board/GetItem",
  // Board content
  BOARD_CONTENT_POST: "BoardContent/CreateItem",
  BOARD_CONTENT_PUT: "BoardContent/UpdateItem",
  BOARD_CONTENT_DELETE: "BoardContent/DeleteItem",
  BOARD_CONTENT_LIST: "BoardContent/GetList",
  BOARD_CONTENT_ITEM: "BoardContent/GetItem",
  // Board content file attachment
  BOARD_CONTENT_FILE_ATTACHMENT_POST: "BoardContentFileAttachment/CreateItem",
  BOARD_CONTENT_FILE_ATTACHMENT_UPLOAD: "BoardContentFileAttachment/UploadFile",
  BOARD_CONTENT_FILE_ATTACHMENT_DELETE: "BoardContentFileAttachment/Delete",
  BOARD_CONTENT_FILE_ATTACHMENT_LIST: "BoardContentFileAttachment/GetList",
  BOARD_CONTENT_FILE_ATTACHMENT_ITEM: "BoardContentFileAttachment/GetItem",
  BOARD_CONTENT_FILE_ATTACHMENT_DOWNLOAD: "BoardContentFileAttachment/Download",
  // Board content network file
  BOARD_CONTENT_NETWORK_FILE: "BoardContentNetworkFile/GetFile",
  BOARD_CONTENT_NETWORK_FILE_IS_PREVIEW:
    "BoardContentNetworkFile/IsPreviewSupported",
  // Board owner
  BOARD_OWNER_POST: "BoardOwner/CreateItem",
  BOARD_OWNER_PUT: "BoardOwner/UpdateItem",
  BOARD_OWNER_DELETE: "BoardOwner/DeleteItem",
  BOARD_OWNER_LIST: "BoardOwner/GetList",
  BOARD_OWNER_ITEM: "BoardOwner/GetItem",
  // Local user
  LOCAL_USER_FEATURE_ENABLED: "LocalUser/FeatureIsEnabled",
  LOCAL_USER_LIST: "LocalUser/GetList",
  LOCAL_USER_ITEM: "LocalUser/GetItem",
  LOCAL_USER_POST: "LocalUser/CreateItem",
  LOCAL_USER_PUT: "LocalUser/UpdateItem",
  LOCAL_USER_PASSWORD_PUT: "LocalUser/UpdatePassword",
  LOCAL_USER_DELETE: "LocalUser/DeleteItem",
  // Hierarchy
  HIERARCHY_POST: "Hierarchy/CreateItem",
  HIERARCHY_PUT: "Hierarchy/UpdateItem",
  HIERARCHY_DELETE: "Hierarchy/DeleteItem",
  HIERARCHY_LIST: "Hierarchy/GetList",
  HIERARCHY_ITEM: "Hierarchy/GetItem",
};
