import React, { ReactElement } from "react";
import { InfoPage } from "../../pages/AppInfo";
import { BoardCreate, BoardPage } from "../../pages/Board";
import { BoardDetail } from "../../pages/Board/BoardDetail";
import { BoardEdit } from "../../pages/Board/BoardEdit";
import { BoardContentCreate } from "../../pages/BoardContent";
import { BoardContentEdit } from "../../pages/BoardContent/BoardContentEdit";
import { BoardOwnerCreate } from "../../pages/BoardOwner";
import { HierarchyCreate, HierarchyPage } from "../../pages/Hierarchy";
import { HierarchyEdit } from "../../pages/Hierarchy/HierarchyEdit";
import { HomePage } from "../../pages/Home/HomePage";
import { LocalUserCreate, LocalUserPage } from "../../pages/LocalUser";
import { LocalUserDetail } from "../../pages/LocalUser/LocalUserEdit";
import { LoginPage } from "../../pages/Login/LoginPage";
import { PermissionCreate, PermissionPage } from "../../pages/Permission";

interface AppRoute {
  path: string;
  component: ReactElement;
}

export const paths = {
  HOME: "/",
  APP_INFO: "/appInfo",
  LOGIN_PAGE: "login",

  // Permissions
  PERMISSIONS: "/permission",
  PERMISSION_CREATE: "/permission/create",
  // Boards
  BOARDS: "/boards",
  BOARD_CREATE: "/board/create",
  BOARD_EDIT: "/board/edit",
  BOARD_DETAIL: "/board/detail",
  // Board content
  BOARD_CONTENT_CREATE: "/content/create",
  BOARD_CONTENT_EDIT: "/content/edit",
  // Board owner
  BOARD_OWNER_CREATE: "/owner/create",
  // Hierarchy
  HIERARCHY: "/hierarchy",
  HIERARCHY_CREATE: "/hierarchy/create",
  HIERARCHY_EDIT: "/hierarchy/edit",
  // LocalUser
  LOCAL_USER: "/localUser",
  LOCAL_USER_CREATE: "/localUser/create",
  LOCAL_USER_DETAIL: "/localUser/detail",
};

export const routes: AppRoute[] = [
  {
    path: paths.HOME,
    component: <HomePage />,
  },
  {
    path: paths.APP_INFO,
    component: <InfoPage />,
  },
  {
    path: paths.LOGIN_PAGE,
    component: <LoginPage />,
  },
  {
    path: paths.PERMISSIONS,
    component: <PermissionPage />,
  },
  {
    path: paths.PERMISSION_CREATE,
    component: <PermissionCreate />,
  },
  {
    path: paths.BOARDS,
    component: <BoardPage />,
  },
  {
    path: paths.BOARD_CREATE,
    component: <BoardCreate />,
  },
  {
    path: `${paths.BOARD_DETAIL}/:id`,
    component: <BoardDetail />,
  },
  {
    path: `${paths.BOARD_EDIT}/:id`,
    component: <BoardEdit />,
  },
  {
    path: `${paths.BOARD_DETAIL}/:parentId${paths.BOARD_CONTENT_EDIT}/:id`,
    component: <BoardContentEdit />,
  },
  {
    path: `${paths.BOARD_DETAIL}/:parentId${paths.BOARD_CONTENT_CREATE}`,
    component: <BoardContentCreate />,
  },
  {
    path: `${paths.BOARD_DETAIL}/:parentId${paths.BOARD_OWNER_CREATE}`,
    component: <BoardOwnerCreate />,
  },
  {
    path: paths.HIERARCHY,
    component: <HierarchyPage />,
  },
  {
    path: paths.HIERARCHY_CREATE,
    component: <HierarchyCreate />,
  },
  {
    path: `${paths.HIERARCHY_EDIT}/:id`,
    component: <HierarchyEdit />,
  },
  {
    path: paths.LOCAL_USER,
    component: <LocalUserPage />,
  },
  {
    path: paths.LOCAL_USER_CREATE,
    component: <LocalUserCreate />,
  },
  {
    path: `${paths.LOCAL_USER_DETAIL}/:id`,
    component: <LocalUserDetail />,
  },
];

export const fullscreenPathPrefix = "/fullscreen";
export const pathFullscreen = {
  BOARD_DETAIL: `${fullscreenPathPrefix}${paths.BOARD_DETAIL}`,
};

const fullscreenBoardDetailBase = `${pathFullscreen.BOARD_DETAIL}/:id`;

export const routesFullscreen: AppRoute[] = [
  {
    path: fullscreenBoardDetailBase,
    component: <BoardDetail />,
  },
];
