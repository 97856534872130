import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { BoardContent } from "../../types/app/boardContent.types";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { dictionary } from "../../utils/core/dictionary";
import "./BoardContent.scss";
import { apiUrl } from "../../api/core/apiUrl";
import http from "../../api/core/api";
import { Fragment, useEffect, useState } from "react";
import { Board } from "../../types/app/board.types";
import { theme } from "../../App";
import { fullscreenPathPrefix } from "../../utils/core/routes";
import { useLocation } from "react-router-dom";
import { localStorageKeys } from "../../utils/keys/localStorageKeys";
import { CustomDialog } from "../../components/core/Dialogs/CustomDialog";
import { BoardContentCardFilePreview } from "./BoardContentCardFilePreview";

interface Props {
  readOnly: boolean;
  boardContent: BoardContent;
  board: Board;
  handleOnDelete: (id: number) => void;
  handleOnEdit: (boardContent: BoardContent) => void;
  handleOnCreate: (boardContent: BoardContent) => void;
}

export function BoardContentCard({
  readOnly,
  boardContent,
  board,
  handleOnDelete,
  handleOnEdit,
  handleOnCreate,
}: Props) {
  const { pathname } = useLocation();
  const isFullScreen = pathname.includes(fullscreenPathPrefix);
  const placeholder = require("../../placeholder.png");
  const [boardContentAttachmentList, setBoardContentAttachmentList] = useState<
    any | null
  >();
  const [attachmentId, setAttachmentId] = useState<number | null>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isPreviewSupported, setIsPreviewSupported] = useState<boolean>(false);
  const [attachmentHeight, setAttachmentHeight] = useState<number>(100);
  const fetchBoardAttachments = async () => {
    if (!boardContent.id) return;
    const response = await http.apiGet({
      url: `${apiUrl.BOARD_CONTENT_FILE_ATTACHMENT_LIST}?parentId=${boardContent.id}`,
    });
    setBoardContentAttachmentList(response?.data);
  };

  useEffect(() => {
    fetchBoardAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!boardContentAttachmentList || boardContentAttachmentList.length === 0)
      return;
    setAttachmentId(boardContentAttachmentList[0].id);
  }, [boardContentAttachmentList]);

  const getAction = () => {
    if (readOnly) return undefined;
    return (
      <Grid>
        {boardContent.id !== null && (
          <>
            <Tooltip title={dictionary.EDIT}>
              <EditIcon
                className={"icon-button"}
                onClick={() => boardContent.id && handleOnEdit(boardContent)}
              />
            </Tooltip>
            <Tooltip title={dictionary.DELETE}>
              <DeleteIcon
                className={"icon-button"}
                onClick={() =>
                  boardContent.id && handleOnDelete(boardContent.id)
                }
              />
            </Tooltip>
          </>
        )}
        {boardContent.id === null && (
          <Tooltip title={dictionary.ADD}>
            <AddIcon
              className={"icon-button"}
              onClick={() => handleOnCreate(boardContent)}
            />
          </Tooltip>
        )}
      </Grid>
    );
  };

  // Zjistit velikost karty, odečíst velikost hlavičky
  useEffect(() => {
    let item = document.getElementsByClassName("MuiCard-root");
    let height = item?.item(0)?.clientHeight;
    setAttachmentHeight(height ? height - 30 : 150);
  }, []);

  const downloadFile = (fileUrl: string, fileName: string) => {
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const authHeaders = () => {
    const token = localStorage.getItem(localStorageKeys.BEARER_TOKEN_KEY);
    if (token) {
      return {
        Authorization: `Bearer ${JSON.parse(token)}`,
      };
    }
    return null;
  };

  const handleFileDownload = async (path: string) => {
    const fileName = path.split("\\");
    await http
      .apiGetFiles({
        url: `${apiUrl.BOARD_CONTENT_NETWORK_FILE}?path=${path}`,
        config: {
          headers: {
            ...authHeaders(),
            "Content-Type": "application/json",
          },
          method: "GET",
          responseType: "blob",
        },
      })
      .then((response) => response?.data)
      .then(async (data) => {
        const fileUrl: string = URL.createObjectURL(data);
        downloadFile(fileUrl, fileName[fileName.length - 1]);
      });
  };

  const getIsPreviewSupported = async (isPreview?: boolean) => {
    await http
      .apiGetFiles({
        url: `${apiUrl.BOARD_CONTENT_NETWORK_FILE_IS_PREVIEW}?path=${boardContent.urlDetail}`,
        config: {
          headers: {
            ...authHeaders(),
            "Content-Type": "application/json",
          },
          method: "GET",
        },
      })
      .then((res) => {
        setIsPreviewSupported(res?.data ? res.data : false);
        if (res?.data) {
          if (!isPreview) setIsModalOpen(res?.data);
        } else if (boardContent.urlDetail) {
          if (!isPreview) handleFileDownload(boardContent.urlDetail);
        } else {
          if (attachmentId) setIsModalOpen(true);
        }
      });
  };

  useEffect(() => {
    if (!boardContent || !boardContent.urlDetail || attachmentId) return;
    getIsPreviewSupported(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModalTitle = () => {
    if (!boardContent.urlDetail) return "Preview";
    const fileName = boardContent.urlDetail.split("\\");
    return fileName[fileName.length - 1];
  };

  return (
    <Fragment key={boardContent.id}>
      <Card
        key={boardContent.id}
        style={{
          height: isFullScreen
            ? `calc((100vh - 95px) / ${board.rows})`
            : `calc((100vh - 170px) / ${board.rows})`,
          border: "1px solid gray",
          boxShadow: "1px #888888",
          margin: 5,
          padding: 0,
          backgroundColor:
            !attachmentId && !boardContent.urlPreview ? "#d0d0d0" : "#fff",
        }}
      >
        <CardHeader
          style={{
            height: "25px",
            minWidth: "100%",
            color: theme.palette.primary.main,
            borderBottom: "1px solid gray",
            paddingLeft: 5,
            whiteSpace: "nowrap",
            backgroundColor: "#fff",
          }}
          action={getAction()}
          title={
            <>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    marginLeft: 5,
                    marginTop: "3px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span
                    title={
                      boardContent.headline ? boardContent.headline : undefined
                    }
                  >
                    {boardContent?.headline}
                  </span>
                </div>
              </div>
            </>
          }
        />
        <CardContent
          style={{
            textAlign: "center",
            padding: 0,
          }}
        >
          {/* je attachment */}
          {boardContent.id && attachmentId && (
            <div
              style={{
                height: attachmentHeight + "px",
                top: 0,
                background: `url("/api/${apiUrl.BOARD_CONTENT_FILE_ATTACHMENT_DOWNLOAD}?id=${attachmentId}")`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top",
                backgroundSize: "contain",
              }}
              onClick={() => {
                if (
                  boardContent &&
                  boardContent.urlDetail &&
                  (boardContent.urlDetail.includes("https") ||
                    boardContent.urlDetail.includes("http"))
                ) {
                  window.open(boardContent.urlDetail, "_self");
                } else if (boardContent.urlDetail) {
                  getIsPreviewSupported();
                } else if (boardContent) {
                  setIsModalOpen(true);
                }
              }}
            />
          )}
          {/* není attachment, je urlPreview */}
          {boardContent.id && !attachmentId && boardContent.urlPreview && (
            <div
              style={{
                height: window.outerHeight * boardContent.urlPreviewZoom + "px",
                width: window.outerWidth * boardContent.urlPreviewZoom + "px",
                display: "inline-block",
                overflow: "hidden",
                position: "relative",
              }}
              onClick={() =>
                boardContent.urlDetail &&
                window.open(boardContent.urlDetail, "_self")
              }
            >
              <div
                style={{
                  position: "relative",
                  transform: `scale(${boardContent.urlPreviewZoom})`,
                  WebkitTransformOrigin: "0 0",
                }}
              >
                <iframe
                  key={
                    boardContent.positionRow + "_" + boardContent.positionColumn
                  }
                  width={
                    boardContent.urlPreviewWidth
                      ? boardContent.urlPreviewWidth
                      : 1920
                  }
                  height={
                    boardContent.urlPreviewHeight
                      ? boardContent.urlPreviewHeight
                      : 1080
                  }
                  src={boardContent.urlPreview}
                  name={boardContent.urlPreview}
                  title={boardContent.urlPreview}
                ></iframe>
              </div>
              <div
                className="overlay"
                onClick={() => {
                  if (
                    boardContent &&
                    boardContent.urlDetail &&
                    (boardContent.urlDetail.includes("https") ||
                      boardContent.urlDetail.includes("http"))
                  ) {
                    window.open(boardContent.urlDetail, "_self");
                  } else if (boardContent.urlDetail) {
                    getIsPreviewSupported();
                  }
                }}
              ></div>
            </div>
          )}
          {/* není attachment, není urlPreview */}
          {!attachmentId &&
          !boardContent.urlPreview &&
          boardContent.urlDetail &&
          isPreviewSupported ? (
            <span
              onClick={() => {
                if (
                  boardContent &&
                  boardContent.urlDetail &&
                  (boardContent.urlDetail.includes("https") ||
                    boardContent.urlDetail.includes("http"))
                ) {
                  window.open(boardContent.urlDetail, "_self");
                } else if (boardContent.urlDetail) {
                  getIsPreviewSupported();
                }
              }}
            >
              <BoardContentCardFilePreview
                fileName={getModalTitle()}
                path={boardContent.urlDetail}
                attachmentHeight={attachmentHeight}
              />
            </span>
          ) : (
            !boardContent.urlDetail && (
              <Skeleton
                sx={{
                  backgroundImage: `url(${placeholder})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat !important",
                  backgroundPosition: "center center",
                }}
                animation={false}
                variant="rectangular"
                height={attachmentHeight + "px"}
                onClick={() => {
                  if (
                    boardContent &&
                    boardContent.urlDetail &&
                    (boardContent.urlDetail.includes("https") ||
                      boardContent.urlDetail.includes("http"))
                  ) {
                    window.open(boardContent.urlDetail, "_self");
                  } else if (boardContent.urlDetail) {
                    getIsPreviewSupported();
                  }
                }}
              />
            )
          )}
        </CardContent>
      </Card>
      <CustomDialog
        open={isModalOpen}
        title={getModalTitle()}
        maxWidth={false}
        onClose={() => setIsModalOpen(false)}
      >
        {boardContent.urlDetail && (
          <BoardContentCardFilePreview
            fileName={getModalTitle()}
            path={boardContent.urlDetail}
          />
        )}
        {!boardContent.urlDetail && attachmentId && (
          <div
            style={{
              minHeight: 80 + "vh",
              width: "auto",
              marginBottom: 0,
              top: 0,
              background: `url("/api/${apiUrl.BOARD_CONTENT_FILE_ATTACHMENT_DOWNLOAD}?id=${attachmentId}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              backgroundSize: "contain",
            }}
          />
        )}
      </CustomDialog>
    </Fragment>
  );
}
