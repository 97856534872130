import React from "react";
import { Typography, Grid } from "@mui/material";
import { theme } from "../../../App";

interface Props {
  title: string;
  buttons?: any;
  xs?: number;
  headlineVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  className?: any;
}

export function PageHeader({
  title,
  buttons,
  xs,
  headlineVariant,
  className,
}: Props) {
  return (
    <Grid
      container
      spacing={2}
      className={className}
      color={theme.palette.primary.main}
    >
      <Grid item flex={1}>
        <Typography variant={headlineVariant || "h4"}>{title}</Typography>
      </Grid>
      <Grid item>
        {buttons?.map((item: any, index: number) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
}
