import HomeIcon from "@mui/icons-material/Home";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GridViewIcon from "@mui/icons-material/GridView";
import * as React from "react";
import { paths } from "./routes";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PersonIcon from "@mui/icons-material/Person";

interface AppNavigationItem {
  title: string;
  path: string;
  icon: any;
  items?: AppNavigationItem[];
}

export const navigation: AppNavigationItem[] = [
  { title: "Home", path: "/", icon: <HomeIcon /> },
  { title: "Boards", path: paths.BOARDS, icon: <GridViewIcon /> },
  { title: "Hierarchy", path: paths.HIERARCHY, icon: <AccountTreeIcon /> },
  { title: "Permissions", path: paths.PERMISSIONS, icon: <VerifiedUserIcon /> },
  { title: "Local user", path: paths.LOCAL_USER, icon: <PersonIcon /> },
  { title: "System info", path: paths.APP_INFO, icon: <InfoOutlinedIcon /> },
];
