import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertDialog } from "../../components/core/Dialogs/AlertDialog";
import http from "../../api/core/api";
import { apiUrl } from "../../api/core/apiUrl";
import { Loader } from "../../components/core/Loader/Loader";
import { useDeleteDialog } from "../../hooks/useDeleteDialog";
import { dictionary } from "../../utils/core/dictionary";
import { Board } from "../../types/app/board.types";
import { BoardContentCard } from "./BoardContentCard";
import { BoardContent } from "../../types/app/boardContent.types";
import { useLocation, useParams } from "react-router-dom";
import { fullscreenPathPrefix, paths } from "../../utils/core/routes";

interface Props {
  readOnly: boolean;
  board: Board;
}
export function BoardContentList({ board, readOnly }: Props) {
  const { pathname } = useLocation();
  const isFullScreen = pathname.includes(fullscreenPathPrefix);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    isOpen: isDeleteDialogOpen,
    idToDelete,
    setDeleteAttempt,
    cancelDeleteAttempt,
    confirmDeleteAttempt,
  } = useDeleteDialog();

  const [boardContentList, setBoardContentList] = useState<
    BoardContent[] | null
  >();

  const fetchBoards = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.BOARD_CONTENT_LIST}?parentId=${id}`,
    });
    setBoardContentList(response?.data);
  };

  useEffect(() => {
    fetchBoards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRemoveItem = async (idToDelete: number | null) => {
    if (idToDelete === null) return;
    const response = await http.apiDelete({
      url: `${apiUrl.BOARD_CONTENT_DELETE}?id=${idToDelete}`,
    });
    if (response?.status === 204) {
      enqueueSnackbar(dictionary.BOARD_DELETED, {
        variant: "success",
      });
      confirmDeleteAttempt();
      fetchBoards();
    }
  };

  const handleOnDelete = (id: number) => setDeleteAttempt(id);
  const handleOnCreate = (boardContent: BoardContent) =>
    navigate(`${paths.BOARD_DETAIL}/${board.id}${paths.BOARD_CONTENT_CREATE}`, {
      state: boardContent,
    });
  const handleOnEdit = (boardContent: BoardContent) =>
    navigate(
      `${paths.BOARD_DETAIL}/${board.id}${paths.BOARD_CONTENT_EDIT}/${boardContent.id}`,
      { state: boardContent }
    );

  const handleConfirm = () => {
    if (!idToDelete) return;
    onRemoveItem(idToDelete);
  };

  const getContentFromList = (rowIndex: number, colIndex: number) => {
    let defaultCard = {
      id: null,
      boardId: board.id,
      positionRow: rowIndex,
      positionColumn: colIndex,
      headline: "",
      urlPreview: null,
      urlDetail: null,
    } as BoardContent;
    if (!boardContentList) return defaultCard;
    let card = boardContentList.find(
      (content: BoardContent) =>
        content.positionRow === rowIndex && content.positionColumn === colIndex
    );
    return card ? card : defaultCard;
  };

  if (!boardContentList) return <Loader />;
  return (
    <>
      <Grid
        container
        sx={{
          height: isFullScreen
            ? `calc((100vh - 95px) / ${board.rows})`
            : `calc((100vh - 170px) / ${board.rows})`,
        }}
      >
        {/* výpis boardů podle indexu řádku a sloupce */}
        {[...Array(board.rows)].map((key, row) => (
          <Fragment key={row}>
            {[...Array(board.cols)].map((key, col) => (
              <Grid item xs={12 / board.cols} key={"col" + col + "_row" + row}>
                <BoardContentCard
                  readOnly={readOnly}
                  key={"col" + col + "_row" + row}
                  board={board}
                  boardContent={getContentFromList(row, col)}
                  handleOnCreate={handleOnCreate}
                  handleOnEdit={handleOnEdit}
                  handleOnDelete={handleOnDelete}
                />
              </Grid>
            ))}
          </Fragment>
        ))}
      </Grid>
      <AlertDialog
        open={isDeleteDialogOpen}
        title={dictionary.DELETE}
        content={dictionary.DELETE_MESSAGE}
        handleClose={cancelDeleteAttempt}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
