import { Grid, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LocalUserInputs } from "../../types/app/localUser.types";
import { dictionary } from "../../utils/core/dictionary";
interface Props {
  onSubmit: SubmitHandler<LocalUserInputs>;
  defaultValues?: LocalUserInputs;
  onBack: any;
  isReadOnly?: boolean;
  isDetail?: boolean;
}

export function LocalUserForm({
  onSubmit,
  defaultValues,
  onBack,
  isReadOnly,
  isDetail,
}: Props) {
  const [tmpPassword1, setTmpPassword1] = useState<string>();
  const [tmpPassword2, setTmpPassword2] = useState<string>();
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<LocalUserInputs>();

  useEffect(() => {
    if (!tmpPassword1 && !tmpPassword2) return;
    if (tmpPassword1 !== tmpPassword2) {
      setError("password", {
        type: "custom",
        message: dictionary.PASSWORDS_DO_NOT_MATCH,
      });
    } else {
      clearErrors();
    }
  }, [tmpPassword1, tmpPassword2, clearErrors, setError]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label={dictionary.LOCAL_USER_ID}
              {...register("id", {
                required: dictionary.ID_IS_REQUIRED,
              })}
              error={Boolean(errors.id)}
              helperText={errors.id?.message}
              disabled={isReadOnly || isDetail}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label={dictionary.LOCAL_USER_NAME}
              {...register("name", {
                required: dictionary.NAME_IS_REQUIRED,
              })}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={dictionary.LOCAL_USER_EMAIL}
              {...register("email")}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              disabled={isReadOnly}
            />
          </Grid>
          {!isDetail && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label={dictionary.LOCAL_USER_PASSWORD}
                  onChange={(e: any) => {
                    if (e.target.value) setTmpPassword1(e.target.value);
                  }}
                  type={"password"}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label={dictionary.LOCAL_USER_PASSWORD2}
                  {...register("password", {
                    onChange: (e: any) => {
                      if (e.target.value) setTmpPassword2(e.target.value);
                    },
                  })}
                  type={"password"}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  disabled={isReadOnly}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button type="button" variant="outlined" onClick={onBack}>
                {dictionary.BACK}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isDetail && (!tmpPassword1 || !tmpPassword2)}
              >
                {dictionary.SAVE}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
