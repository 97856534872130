import { Grid, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { dictionary } from "../../utils/core/dictionary";
import http from "../../api/core/api";
import { apiUrl } from "../../api/core/apiUrl";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteDialog } from "../../hooks/useDeleteDialog";
import { AlertDialog } from "../../components/core/Dialogs/AlertDialog";
interface Props {
  attachmentId: number | null;
  fetchBoardAttachments: () => void;
}

export function BoardContentFileUploadForm({
  attachmentId,
  fetchBoardAttachments,
}: Props) {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { register, setValue } = useForm();

  const {
    isOpen: isDeleteDialogOpen,
    idToDelete,
    setDeleteAttempt,
    cancelDeleteAttempt,
    confirmDeleteAttempt,
  } = useDeleteDialog();

  // Soubor byl vybrán
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    // Soubor neexistuje
    if (!event.target.files) return;
    setSelectedFile(event.target.files[0]);
  };

  // Potvrzení nahrání souboru
  const handleOnSubmitAttachment = async () => {
    if (!selectedFile) return;
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const response = await http.apiPostFiles({
        url: `${apiUrl.BOARD_CONTENT_FILE_ATTACHMENT_UPLOAD}?parentId=${id}`,
        data: formData,
      });
      if (response?.status === 200) {
        enqueueSnackbar(dictionary.BOARD_CONTENT_ATTACHMENT_UPLOADED, {
          variant: "success",
        });
        fetchBoardAttachments();
        setValue("files", null);
        setSelectedFile(null);
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  const onRemoveItem = async (idToDelete: number | null) => {
    if (idToDelete === null) return;
    const response = await http.apiDelete({
      url: `${apiUrl.BOARD_CONTENT_FILE_ATTACHMENT_DELETE}?id=${idToDelete}`,
    });
    if (response?.status === 204) {
      enqueueSnackbar(dictionary.BOARD_CONTENT_FILE_ATTACHMENT_DELETED, {
        variant: "success",
      });
      confirmDeleteAttempt();
      setValue("files", null);
      setSelectedFile(null);
      fetchBoardAttachments();
    }
  };

  const handleConfirm = () => {
    if (!idToDelete) return;
    onRemoveItem(idToDelete);
  };

  return (
    <>
      <Grid item xs={12}>
        <Stack spacing={2} direction="row">
          <input
            id="contained-button-file"
            type="file"
            title="Select attachment"
            {...register("files")}
            onChange={handleFileSelect}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnSubmitAttachment}
          >
            {dictionary.UPLOAD}
          </Button>
        </Stack>
      </Grid>
      {attachmentId && (
        <>
          <div
            style={{
              minHeight: "250px",
              marginTop: 10,
              background: `url("/api/${apiUrl.BOARD_CONTENT_FILE_ATTACHMENT_DOWNLOAD}?id=${attachmentId}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          />

          <Button
            variant="outlined"
            onClick={() => {
              setDeleteAttempt(attachmentId);
            }}
            style={{ marginTop: 10 }}
            startIcon={<DeleteIcon />}
          >
            {dictionary.DELETE}
          </Button>
        </>
      )}
      <AlertDialog
        open={isDeleteDialogOpen}
        title={dictionary.DELETE}
        content={dictionary.DELETE_MESSAGE}
        handleClose={cancelDeleteAttempt}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
