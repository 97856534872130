import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";

interface Props {
  label: string;
  controllerProps: any;
  errors?: any;
  readOnly?: boolean;
}

export function CheckBox({ label, controllerProps, readOnly }: Props) {
  return (
    <Controller
      {...controllerProps}
      defaultValue={false}
      render={({ field }: any) => {
        return (
          <FormControlLabel
            disabled={readOnly}
            control={
              <Checkbox
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
                checked={field.value}
              />
            }
            label={label}
          />
        );
      }}
    />
  );
}
