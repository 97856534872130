import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Paper, TableContainer, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertDialog } from "../../components/core/Dialogs/AlertDialog";
import http from "../../api/core/api";
import { apiUrl } from "../../api/core/apiUrl";
import DataGridTable from "../../components/core/DataGridTable/DataGridTable";
import { Loader } from "../../components/core/Loader/Loader";
import { useDeleteDialog } from "../../hooks/useDeleteDialog";
import { dictionary } from "../../utils/core/dictionary";
import { Hierarchy } from "../../types/app/hierarchy.types";
import EditIcon from "@mui/icons-material/Edit";
import { paths } from "../../utils/core/routes";

interface Props {
  readOnly: boolean;
  parentId?: number;
}
export function HierarchyList({ readOnly, parentId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    isOpen: isDeleteDialogOpen,
    idToDelete,
    setDeleteAttempt,
    cancelDeleteAttempt,
    confirmDeleteAttempt,
  } = useDeleteDialog();

  const [hierarchyList, setHierarchyList] = useState<Hierarchy[] | null>();

  const fetchHierarchy = async () => {
    let req = apiUrl.HIERARCHY_LIST;
    if (parentId) req += `?parentId=${parentId}`;
    const response = await http.apiGet({
      url: req,
    });
    let data = response?.data;
    // pokud nemám parent id, zobrazím jen top level hierarchie
    if (!parentId)
      data = response?.data.filter((item: any) => item.parentId === null);
    setHierarchyList(data);
  };

  useEffect(() => {
    fetchHierarchy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  const onRemoveItem = async (idToDelete: number | null) => {
    if (idToDelete === null) return;
    const response = await http.apiDelete({
      url: `${apiUrl.HIERARCHY_DELETE}?id=${idToDelete}`,
    });
    if (response?.status === 204) {
      enqueueSnackbar(dictionary.HIERARCHY_DELETED, {
        variant: "success",
      });
      confirmDeleteAttempt();
      fetchHierarchy();
    }
  };

  const columns = [
    {
      headerName: "Parent name",
      field: "parentName",
      width: 200,
    },
    {
      headerName: "Name",
      field: "name",
      width: 200,
    },
    {
      field: " ",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      align: "right",
      renderCell: (params: any) => {
        return (
          <>
            {!readOnly && (
              <>
                <Tooltip title={"Edit"}>
                  <Button
                    style={{ minWidth: 30 }}
                    onClick={() => handleOnEdit(params.id)}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={dictionary.DELETE}>
                  <Button
                    style={{ minWidth: 30 }}
                    onClick={() => handleOnDelete(params.id)}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
          </>
        );
      },
    },
  ];
  const handleOnCreate = () =>
    navigate(paths.HIERARCHY_CREATE, { state: parentId });
  const handleOnEdit = (id: number) => {
    navigate(`${paths.HIERARCHY_EDIT}/${id}`, { state: parentId });
  };
  const handleOnDelete = (id: number) => setDeleteAttempt(id);

  const handleConfirm = () => {
    if (!idToDelete) return;

    onRemoveItem(idToDelete);
  };

  if (!hierarchyList) return <Loader />;
  return (
    <>
      <TableContainer component={Paper}>
        <DataGridTable
          height={"70vh"}
          data={hierarchyList}
          columns={columns}
          fulltextSearch={true}
          autoPageSize={true}
        />
        {!readOnly && (
          <Tooltip title={dictionary.NEW}>
            <Button onClick={handleOnCreate}>
              <AddIcon />
            </Button>
          </Tooltip>
        )}
      </TableContainer>
      <AlertDialog
        open={isDeleteDialogOpen}
        title={dictionary.DELETE}
        content={dictionary.DELETE_MESSAGE}
        handleClose={cancelDeleteAttempt}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
