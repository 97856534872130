import { Box, Grid, Paper, Typography } from "@mui/material";
import { Loader } from "../../components/core/Loader/Loader";
import { AppInfo } from "../../types/app/appInfo.types";
import http from "../../api/core/api";
import { useState, useEffect } from "react";
import { apiUrl } from "../../api/core/apiUrl";
import { theme } from "../../App";

interface Props {
  title: string;
  data?: string;
}

const InfoPageRow = ({ title, data }: Props) => {
  return (
    <Grid container item spacing={3} style={{ minWidth: "350px" }}>
      <Grid item xs={5}>
        <Typography variant={"body1"}>{title}</Typography>
        <Box mr={3} />
      </Grid>
      <Grid item xs={7}>
        <Typography variant={"body1"} color={"primary"}>
          {data}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const InfoPage = () => {
  const [data, setData] = useState<AppInfo | null>();
  const fetchAppInfo = async () => {
    const response = await http.apiGet({ url: apiUrl.APP_INFO });
    setData(response?.data);
  };

  useEffect(() => {
    fetchAppInfo();
  }, []);

  if (!data) return <Loader />;
  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      <Grid item>
        <Box mt={5} />
        <Typography
          variant={"h4"}
          style={{ textAlign: "center" }}
          color={theme.palette.primary.main}
        >
          {data?.appName}
        </Typography>
        <Box mb={3} />
      </Grid>
      <Grid item>
        <Typography variant={"h5"}>{data?.author}</Typography>
      </Grid>
      <Grid
        item
        container
        direction={"row"}
        spacing={4}
        justifyContent={"center"}
      >
        <Grid item>
          <Typography variant={"body1"}>{data?.authorContactEmail}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={"body1"}>{data?.authorContactMobile}</Typography>
          <Box mb={5} />
        </Grid>
      </Grid>
      <Grid item container direction={"row"} justifyContent={"center"}>
        <Box mt={5} />
        <Paper>
          <Box px={10} py={2} style={{ minWidth: "350px" }}>
            <Grid container spacing={2} direction={"column"}>
              <InfoPageRow title={"Version:"} data={data?.version} />
              <InfoPageRow title={"Released:"} data={data?.versionDate} />
              <InfoPageRow title={"Database:"} data={data?.database} />
              <InfoPageRow
                title={"Database version:"}
                data={data?.databaseServerVersion}
              />
              <InfoPageRow title={"ENV name:"} data={data?.environmentName} />
              <InfoPageRow title={"Machine:"} data={data?.machineName} />
              <InfoPageRow title={"App pool:"} data={data?.appPool} />
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
