import { Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { apiUrl } from "../../api/core/apiUrl";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { Hierarchy, HierarchyInputs } from "../../types/app/hierarchy.types";
import { dictionary } from "../../utils/core/dictionary";
import { HierarchyForm } from "./HierarchyForm";
import http from "../../api/core/api";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../../components/core/Loader/Loader";
import { HierarchyList } from "./HierarchyList";
import { useAuth } from "../../contexts/authContext";
import { UserRoles } from "../../types/core/auth.types";
import { paths } from "../../utils/core/routes";

export function HierarchyEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const { user } = useAuth();
  // jen admin může editovat hierarchy
  const readOnly = user
    ? user.roles.indexOf(UserRoles.Admin) > -1
      ? false
      : true
    : true;

  const [defaultValues, setDefaultValues] = useState<HierarchyInputs | null>(
    null
  );
  const [data, setData] = useState<Hierarchy | null>(null);

  const fetchHierarchy = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.HIERARCHY_ITEM}?id=${id}`,
    });
    setData(response?.data);
  };

  useEffect(() => {
    fetchHierarchy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!data) return;

    setDefaultValues({
      id: data.id,
      parentId: data.parentId,
      name: data.name,
    });
  }, [data]);

  const convertDataFromInputToRequest = (data: HierarchyInputs) => ({
    id: data.id,
    parentId: data.parentId,
    name: data.name,
  });

  const handleOnSubmit: SubmitHandler<HierarchyInputs> = async (
    values: HierarchyInputs
  ) => {
    try {
      let data = convertDataFromInputToRequest(values);
      const response = await http.apiPut({
        url: apiUrl.HIERARCHY_PUT,
        data: data,
      });
      if (response?.status === 200) {
        enqueueSnackbar(dictionary.HIERARCHY_EDITED, {
          variant: "success",
        });
        navigate(
          state ? `${paths.HIERARCHY_EDIT}/${state}` : `${paths.HIERARCHY}`
        );
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  if (!defaultValues) return <Loader />;
  return (
    <>
      <PageHeader title={dictionary.HIERARCHY_EDIT} />
      <PageContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <Paper>
              <HierarchyForm
                onSubmit={handleOnSubmit}
                onBack={() =>
                  navigate(
                    state
                      ? `${paths.HIERARCHY_EDIT}/${state}`
                      : `${paths.HIERARCHY}`
                  )
                }
                defaultValues={defaultValues}
                readOnly={readOnly}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={9}>
            <HierarchyList readOnly={readOnly} parentId={Number(id)} />
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
