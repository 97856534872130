import axios, { AxiosRequestConfig } from "axios";
import { localStorageKeys } from "../../utils/keys/localStorageKeys";

const commonHeaders = {
  "Content-Type": "application/json",
};

export const api = axios.create({
  baseURL: "/api/",
  headers: commonHeaders,
});
const authHeaders = () => {
  const token = localStorage.getItem(localStorageKeys.BEARER_TOKEN_KEY);
  if (token) {
    return {
      Authorization: `Bearer ${JSON.parse(token)}`,
    };
  }
  return null;
};

const baseClient = axios.create({
  baseURL: "/api/",
  headers: commonHeaders,
});

const baseClientFiles = axios.create({
  baseURL: "/api/",
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "*/*",
  },
});
interface ApiBaseParams {
  url: string;
  config?: AxiosRequestConfig | null;
}

interface ApiGetParams extends ApiBaseParams {
  params?: object | null;
}

interface ApiPostParams extends ApiBaseParams {
  data?: object | null;
}

interface ApiPutParams extends ApiBaseParams {
  data?: object | null;
}

interface ApiDeleteParams extends ApiBaseParams {
  params?: object | null;
}

const apiGet = ({ url, /*params, */ config }: ApiGetParams) => {
  const response = baseClient.get(url, {
    ...config,
    headers: { ...authHeaders() },
  });
  return response;
};

const apiGetFiles = async ({ url, config }: ApiGetParams) => {
  try {
    const response = await baseClientFiles.get(url, {
      ...config,
      headers: { ...authHeaders() },
    });
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const apiPost = async ({ url, data, config }: ApiPostParams) => {
  try {
    const response = await baseClient.post(url, data, {
      ...config,
      headers: { ...authHeaders() },
    });
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const apiPostFiles = async ({ url, data, config }: ApiPostParams) => {
  try {
    const response = await baseClientFiles.post(url, data, {
      ...config,
      headers: { ...authHeaders() },
    });
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const apiPut = async ({ url, data, config }: ApiPutParams) => {
  try {
    const response = await baseClient.put(url, data, {
      ...config,
      headers: { ...authHeaders() },
    });
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const apiDelete = async ({ url, /*params, */ config }: ApiDeleteParams) => {
  try {
    const response = await baseClient.delete(url, {
      ...config,
      headers: { ...authHeaders() },
    });
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const postHeaders = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseClient,
  apiGet,
  apiPost,
  apiPut,
  apiGetFiles,
  apiPostFiles,
  apiDelete,
};
