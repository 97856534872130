import { Grid, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Loader } from "../../components/core/Loader/Loader";
import { SelectBoxAutocomplete } from "../../components/core/SelectBox/SelectBoxAutocomplete";
import { SelectBoxAutocompleteAsync } from "../../components/core/SelectBox/SelectBoxAutocompleteAsync";
import { PermissionInputs } from "../../types/app/permission.types";
import { Role } from "../../types/app/role.types";
import { dictionary } from "../../utils/core/dictionary";
import http from "../../api/core/api";
import { apiUrl } from "../../api/core/apiUrl";

interface Props {
  onSubmit: SubmitHandler<PermissionInputs>;
  defaultValues?: PermissionInputs;
  onBack: any;
}

export function PermissionForm({ onSubmit, defaultValues, onBack }: Props) {
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<PermissionInputs>();
  const [roleList, setRoleList] = useState<Role[] | null>();

  const fetchRoles = async () => {
    const response = await http.apiGet({ url: apiUrl.ROLE_LIST });
    setRoleList(response?.data);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  if (!roleList) return <Loader />;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectBoxAutocompleteAsync
              isRequired
              label={"User id"}
              valueKey={"netId"}
              labelKey={"fullName"}
              controllerProps={{
                name: "userId",
                control: control,
                rules: {
                  required: "User id is required",
                },
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectBoxAutocomplete
              isRequired
              label={"Identifier"}
              items={roleList}
              valueKey={"identifier"}
              labelKey={"description"}
              controllerProps={{
                name: "identifier",
                control: control,
                rules: {
                  required: "Identifier is required",
                },
              }}
              errors={errors}
            />
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button type="button" variant="outlined" onClick={onBack}>
                {dictionary.BACK}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {dictionary.SAVE}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
