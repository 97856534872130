import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Paper, TableContainer, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertDialog } from "../../../src/components/core/Dialogs/AlertDialog";
import http from "../../api/core/api";
import { apiUrl } from "../../api/core/apiUrl";
import DataGridTable from "../../components/core/DataGridTable/DataGridTable";
import { Loader } from "../../components/core/Loader/Loader";
import { useDeleteDialog } from "../../hooks/useDeleteDialog";
import { dictionary } from "../../utils/core/dictionary";

interface Props {
  isReadOnly: boolean;
}
export function PermissionList({ isReadOnly }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    isOpen: isDeleteDialogOpen,
    idToDelete,
    setDeleteAttempt,
    cancelDeleteAttempt,
    confirmDeleteAttempt,
  } = useDeleteDialog();

  const [permissionList, setPermissionList] = useState<Permissions[] | null>();

  const fetchPermissions = async () => {
    const response = await http.apiGet({ url: apiUrl.PERMISSION_LIST });
    setPermissionList(response?.data);
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  const onRemoveItem = async (idToDelete: number | null) => {
    if (idToDelete === null) return;
    const response = await http.apiDelete({
      url: `${apiUrl.PERMISSION_DELETE}?id=${idToDelete}`,
    });
    if (response?.status === 204) {
      enqueueSnackbar(dictionary.PERMISSION_DELETED, {
        variant: "success",
      });
      confirmDeleteAttempt();
      fetchPermissions();
    }
  };

  const columns = [
    {
      headerName: "Name",
      field: "userName",
      width: 250,
    },
    {
      headerName: "Description",
      field: "description",
      width: 250,
    },
    {
      field: " ",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      align: "right",
      renderCell: (params: any) => {
        return (
          <>
            {!isReadOnly && (
              <Tooltip title={dictionary.DELETE}>
                <Button onClick={() => handleOnDelete(params.id)}>
                  <DeleteIcon />
                </Button>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];
  const handleOnCreate = () => navigate(`/permission/create`);
  const handleOnDelete = (id: number) => setDeleteAttempt(id);

  const handleConfirm = () => {
    if (!idToDelete) return;

    onRemoveItem(idToDelete);
  };

  if (!permissionList) return <Loader />;
  return (
    <>
      <TableContainer component={Paper}>
        <DataGridTable
          height={"70vh"}
          data={permissionList}
          columns={columns}
          fulltextSearch={true}
          autoPageSize={true}
        />
        {!isReadOnly && (
          <Tooltip title={dictionary.NEW}>
            <Button onClick={handleOnCreate}>
              <AddIcon />
            </Button>
          </Tooltip>
        )}
      </TableContainer>
      <AlertDialog
        open={isDeleteDialogOpen}
        title={dictionary.DELETE}
        content={dictionary.DELETE_MESSAGE}
        handleClose={cancelDeleteAttempt}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
