import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid, Paper, TableContainer, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { LocalUser } from "../../../src/types/app/localUser.types";
import { apiUrl } from "../../api/core/apiUrl";
import DataGridTable from "../../components/core/DataGridTable/DataGridTable";
import { Loader } from "../../components/core/Loader/Loader";
import { useDeleteDialog } from "../../hooks/useDeleteDialog";
import { dictionary } from "../../utils/core/dictionary";
import http from "../../api/core/api";
import { AlertDialog } from "../../components/core/Dialogs/AlertDialog";

interface Props {
  isReadOnly: boolean;
}
export function LocalUserList({ isReadOnly }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    isOpen: isDeleteDialogOpen,
    idToDelete,
    setDeleteAttempt,
    cancelDeleteAttempt,
    confirmDeleteAttempt,
  } = useDeleteDialog();

  const [localUserList, setLocalUserList] = useState<LocalUser[] | null>();

  const fetchLocalUsers = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.LOCAL_USER_LIST}`,
    });
    setLocalUserList(response?.data);
  };

  useEffect(() => {
    fetchLocalUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { headerName: "Id", field: "id", width: 150 },
    { headerName: "Name", field: "name", width: 200 },
    { headerName: "Email", field: "email", width: 200 },
    {
      field: " ",
      sortable: false,
      disableColumnMenu: true,
      hide: isReadOnly,
      minWidth: 100,
      flex: 1,
      align: "right",
      renderCell: (params: any) => {
        return (
          <>
            {!isReadOnly && (
              <>
                <Tooltip title={dictionary.EDIT}>
                  <Button
                    style={{ minWidth: 30 }}
                    onClick={() => handleOnEdit(params.id)}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={dictionary.DELETE}>
                  <Button
                    style={{ minWidth: 30 }}
                    onClick={() => handleOnDelete(params.id)}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleOnEdit = (id: number) => navigate(`/localUser/detail/${id}`);
  const handleOnCreate = () => navigate(`/localUser/create`);
  const handleOnDelete = (id: number) => setDeleteAttempt(id);

  const onRemoveItem = async (idToDelete: number | null) => {
    if (idToDelete === null) return;
    const response = await http.apiDelete({
      url: `${apiUrl.LOCAL_USER_DELETE}?id=${idToDelete}`,
    });
    if (response?.status === 204) {
      enqueueSnackbar(dictionary.LOCAL_USER_DELETED, {
        variant: "success",
      });
      confirmDeleteAttempt();
      fetchLocalUsers();
    }
  };

  const handleConfirm = () => {
    if (!idToDelete) return;
    onRemoveItem(idToDelete);
  };

  if (!localUserList) return <Loader />;
  return (
    <>
      <Grid item xs={8}>
        <TableContainer component={Paper}>
          <div style={{ height: "70vh", width: "100%" }}>
            <DataGridTable
              height={"70vh"}
              data={localUserList}
              columns={columns}
              fulltextSearch={true}
              autoPageSize={true}
            />
          </div>
          {!isReadOnly && (
            <Tooltip title={dictionary.NEW}>
              <Button onClick={handleOnCreate}>
                <AddIcon />
              </Button>
            </Tooltip>
          )}
        </TableContainer>
      </Grid>
      <AlertDialog
        open={isDeleteDialogOpen}
        title={dictionary.DELETE}
        content={dictionary.DELETE_MESSAGE}
        handleClose={cancelDeleteAttempt}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
