import React from "react";
import { ReactNode } from "react";
import { Content } from "./Content/Content";
import { Navigation } from "./Navigation/Navigation";

interface Props {
  children: ReactNode;
}

export function Layout({ children }: Props) {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <Navigation />
      <Content>{children}</Content>
    </div>
  );
}
