export interface AppUser {
  id: string;
  name: string;
  email: string;
  roles: Array<string>;
}
export interface SignInRequest {
  username: string;
  password: string;
}

export enum UserRoles {
  Admin = "admin",
}
