import { apiUrl } from "../../api/core/apiUrl";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { Board } from "../../types/app/board.types";
import {} from "./BoardForm";
import http from "../../api/core/api";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../../components/core/Loader/Loader";
import { BoardContentList } from "../BoardContent/BoardContentList";
import { BoardOwner } from "../../types/app/boardOwner.types";
import { useAuth } from "../../contexts/authContext";
import { Button, Grid, Typography } from "@mui/material";
import { fullscreenPathPrefix, pathFullscreen } from "../../utils/core/routes";
import { theme } from "../../App";

export function BoardDetail() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const { user } = useAuth();
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [data, setData] = useState<Board | null>(null);
  const [ownerList, setOwnerList] = useState<BoardOwner[] | null>(null);
  const isFullScreen = pathname.includes(fullscreenPathPrefix);
  const fetchBoard = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.BOARD_ITEM}?id=${id}`,
    });
    setData(response?.data);
  };

  const fetchBoardOwners = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.BOARD_OWNER_LIST}?parentId=${id}`,
    });
    setOwnerList(response?.data);
  };

  useEffect(() => {
    fetchBoard();
    fetchBoardOwners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // pokud jsem ve fullscreenu nebo nejsem owner, tak readonly
    if (!ownerList || pathname.includes(fullscreenPathPrefix)) return;
    if (!user) return setReadOnly(true);
    let owner = ownerList.find((owner: BoardOwner) => owner.userId === user.id);
    if (owner) setReadOnly(false);
  }, [ownerList, user, pathname]);

  // Otevře fullscreen board detailu v novém okně
  const handleOpenFullScreen = () => {
    window.open(
      `${pathFullscreen.BOARD_DETAIL}/${id}`,
      "toolbar=no,resizable=yes,location=yes"
    );
  };

  // refresh stránky každých 5min
  setInterval(function () {
    window.location.reload();
  }, 300000);

  if (!data || !ownerList) return <Loader />;
  return (
    <>
      {isFullScreen && (
        <Grid
          container
          style={{
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
            width: isFullScreen ? "100%" : "-webkit-fill-available",
            display: "flex",
            height: 65,
            position: "absolute",
            top: isFullScreen ? 0 : 64,
            // drawer má šířku 240px
            // left: isFullScreen ? 0 : 240,
            left: 0,
            padding: 16,
          }}
        >
          <Grid item flex={11} style={{ marginLeft: isFullScreen ? 0 : 240 }}>
            <Typography variant={"h4"}>{data.headline}</Typography>
          </Grid>
          <Grid item flex={1}>
            <img
              src={"/logo/te-logo-white.png"}
              alt={"Application logo"}
              title={"Application logo"}
              style={{
                height: "40px",
              }}
            />
          </Grid>
        </Grid>
      )}

      <Grid item flex={1} sx={{ marginTop: isFullScreen ? "64px" : 0 }}>
        {!isFullScreen && (
          <Grid item xs={12}>
            <Typography
              variant={"h4"}
              color={theme.palette.primary.main}
              sx={{ display: "inline" }}
            >
              {data.headline}
            </Typography>
            <Grid item flex={1} sx={{ float: "right" }}>
              <Button
                variant="contained"
                onClick={() => handleOpenFullScreen()}
              >
                Fullscreen
              </Button>
            </Grid>
          </Grid>
        )}
        <PageContent>
          <BoardContentList readOnly={readOnly} board={data} />
        </PageContent>
      </Grid>
    </>
  );
}
