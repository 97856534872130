import React from "react";
import { Controller } from "react-hook-form";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";

interface Props {
  label: string;
  items: any[];
  labelKey: string;
  valueKey: string;
  controllerProps: any;
  errors?: any;
}

export function SelectBox({
  label,
  items,
  labelKey,
  valueKey,
  controllerProps,
  errors,
}: Props) {
  return (
    <FormControl fullWidth>
      <InputLabel id={label}>{label}</InputLabel>
      <Controller
        {...controllerProps}
        render={({ field }) => (
          <>
            <Select
              label={label}
              {...field}
              error={Boolean(errors[controllerProps.name])}
              labelId={label}
            >
              {items.map((item) => (
                <MenuItem key={item[valueKey]} value={item[valueKey]}>
                  {item[labelKey]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={Boolean(errors[controllerProps.name])}>
              {errors[controllerProps.name]?.message}
            </FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
}
