import {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { AppUser } from "../types/core/auth.types";
import http from "../api/core/api";
import useLocalStorage from "../hooks/useLocalStorage";
import { localStorageKeys } from "../utils/keys/localStorageKeys";
import { apiUrl } from "../api/core/apiUrl";
import { useNavigate } from "react-router-dom";
import { paths } from "../utils/core/routes";
import { dictionary } from "../utils/core/dictionary";
import { useSnackbar } from "notistack";

interface AuthContextType {
  user: AppUser | null;
  signIn?: (username: string, password: string, persist: boolean) => void;
  signOut?: () => void;
  isLoading?: boolean;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
});
const useAuth = () => useContext<AuthContextType>(AuthContext);

function AuthProvider(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [storedUser, setStoredUser] = useLocalStorage<AppUser | null>(
    localStorageKeys.USER_KEY,
    null
  );
  const [storedToken, setStoredToken] = useLocalStorage<string | null>(
    localStorageKeys.BEARER_TOKEN_KEY,
    null
  );
  const [user, setUser] = useState<AppUser | null>(storedUser);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const revalidateToken = async () => {
    try {
      const response = await http.apiGet({
        url: apiUrl.GERNERATE_TOKEN_CURR_USER,
      });
      if (response?.status === 200) {
        const { token } = response.data;
        setStoredToken(token);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (storedToken) revalidateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentUser = useCallback(async () => {
    if (user && storedUser) return;
    try {
      const response = await http.apiGet({
        url: `${apiUrl.GET_CURRENT_USER}`,
      });
      if (response?.status === 200) {
        const user = response.data;
        setUser(user);
        setStoredUser(user);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, [setUser, setStoredUser, storedUser, user]);

  const signIn = useCallback(
    async (
      username: string,
      password: string,
      persist: boolean,
      token?: string
    ) => {
      setIsLoading(true);
      if (token) setStoredToken(token);
      try {
        const response = await http.apiPost({
          url: `${
            apiUrl.LOGIN
          }?username=${username}&password=${encodeURIComponent(
            password
          )}&persist=${persist}`,
          data: {},
        });
        if (response?.status === 200) {
          const { token } = response.data;
          setStoredToken(token);
          getCurrentUser();
        } else {
          enqueueSnackbar(dictionary.LOGIN_FAILED, {
            variant: "error",
          });
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    },
    [getCurrentUser, setStoredToken, enqueueSnackbar]
  );

  const signOut = () => {
    if (storedToken) setStoredToken(null);
    if (storedUser) setStoredUser(null);
    setUser(null);
    navigate(paths.LOGIN_PAGE);
  };

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, isLoading }}
      {...props}
    />
  );
}

export { AuthProvider, useAuth };
