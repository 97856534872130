import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { AppBar } from './NavMenu.styles';
import { NavMenuProfile } from './NavMenuProfile';

interface Props {
  open: boolean;
  toggleDrawer: () => void;
}

export function NavMenu({ open, toggleDrawer }: Props) {
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <NavMenuProfile />
      </Toolbar>
    </AppBar>
  );
}
