import React from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import { Box } from '@mui/system';

interface AppNavigationItem {
  title: string;
  path: string;
  icon: any;
  items?: AppNavigationItem[];
  submenu?: boolean;
}
interface Props {
  id: number;
  name: string;
  location: string;
  icon: React.ReactElement;
  items?: AppNavigationItem[];
  handleClick: (e: any) => void;
  open: boolean;
}

export const SidebarMenuItem = ({ id, name, location, icon, items, handleClick, open }: Props) => (
  <>
    {items ? (
      <List>
        {
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary={name} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        }
        {
          <Collapse in={open} timeout="auto" unmountOnExit>
            {items.map((item: AppNavigationItem, index) => {
              return (
                <Box ml={3} key={index}>
                  <List component="div">
                    <ListItem button key={id} to={item.path} component={NavLink}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  </List>
                </Box>
              );
            })}
          </Collapse>
        }
      </List>
    ) : (
      <ListItem button key={id} to={location} component={NavLink}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    )}
  </>
);
