import { Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { apiUrl } from "../../api/core/apiUrl";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { BoardInputs } from "../../types/app/board.types";
import { dictionary } from "../../utils/core/dictionary";
import { BoardForm } from "./BoardForm";
import http from "../../api/core/api";

export function BoardCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // TODO - kdo může vytvářet boardy?
  const readOnly = false;
  const convertDataFromInputToRequest = (data: BoardInputs) => ({
    id: data.id,
    headline: data.headline,
    hierarchyId: data.hierarchyId,
    name: data.name,
    rows: data.rows,
    cols: data.cols,
  });

  const handleOnSubmit: SubmitHandler<BoardInputs> = async (
    values: BoardInputs
  ) => {
    try {
      let data = convertDataFromInputToRequest(values);
      const response = await http.apiPost({
        url: apiUrl.BOARD_POST,
        data: data,
      });
      if (response?.status === 201) {
        enqueueSnackbar(dictionary.BOARD_CREATED, {
          variant: "success",
        });
        navigate(`/boards`);
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  return (
    <>
      <PageHeader title={dictionary.BOARD_CREATE} />
      <PageContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <Paper>
              <BoardForm
                onSubmit={handleOnSubmit}
                onBack={() => navigate("/boards")}
                readOnly={readOnly}
              />
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
