import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LocalUserInputs } from "../../types/app/localUser.types";
import { dictionary } from "../../utils/core/dictionary";
interface Props {
  onSubmit: SubmitHandler<LocalUserInputs>;
  defaultValues?: LocalUserInputs;
  isReadOnly?: boolean;
}

export function LocalUserPasswordChangeForm({
  onSubmit,
  defaultValues,
  isReadOnly,
}: Props) {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<LocalUserInputs>();

  const [tmpPassword1, setTmpPassword1] = useState<string>();
  const [tmpPassword2, setTmpPassword2] = useState<string>();

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!tmpPassword1 && !tmpPassword2) return;
    if (tmpPassword1 !== tmpPassword2) {
      setError("password", {
        type: "custom",
        message: dictionary.PASSWORDS_DO_NOT_MATCH,
      });
    } else {
      clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpPassword1, tmpPassword2, clearErrors, setError]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label={dictionary.LOCAL_USER_PASSWORD}
              onChange={(e: any) => {
                if (e.target.value) setTmpPassword1(e.target.value);
              }}
              type={"password"}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label={dictionary.LOCAL_USER_PASSWORD2}
              {...register("password", {
                onChange: (e: any) => {
                  if (e.target.value) setTmpPassword2(e.target.value);
                },
              })}
              type={"password"}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={Boolean(errors.password)}
              fullWidth
            >
              {dictionary.CHANGE_PASSWORD}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
