import { Autocomplete, FormControl, TextField } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useQuery } from "react-query";
import * as api from "../../../api/core/authWindows";
import { AppUser } from "../../../types/core/auth.types";

// TODO: Zobecnit - nyní funguje pouze pro vyhledávání uživatele
// TODO: Jedná se o draft, bylo by dobré přidat nějaký timeout, aby se nezačalo vyhledávat ihned.
// Nyní si autocomplete pamatuje poslední hodnoty, když smažu inputValue - asi chtěné.

interface Props {
  label: string;
  labelKey: string;
  valueKey: string;
  controllerProps: any;
  errors?: any;
  defaultValue?: string;
  readOnly?: boolean;
  isRequired?: boolean;
}

export function SelectBoxAutocompleteAsync({
  label,
  // labelKey,
  // valueKey,
  controllerProps,
  errors,
  defaultValue,
  readOnly,
  isRequired,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [items, setItems] = useState<any>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState<string>("");

  // Call se provede půl vteřiny po posledním stisku klávesy.
  const { data, isLoading } = useQuery<AppUser[]>(
    ["searchUser", inputValue],
    () => api.getUserSearch(debouncedValue),
    { enabled: !!inputValue && !!debouncedValue }
  );

  useEffect(() => {
    if (!data) return;

    setItems([...data]);
    setDebouncedValue("");
  }, [data]);

  /**
   * Při zmáčknutí klávesy změníme časovou značku.
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedValue(inputValue), 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  return (
    <FormControl fullWidth>
      <Controller
        {...controllerProps}
        defaultValue={defaultValue ? defaultValue : null}
        render={({ field }: any) => (
          <>
            <Autocomplete
              {...field}
              clearOnEscape
              options={items}
              loading={isLoading}
              getOptionLabel={(option: AppUser) => {
                return typeof option === "string" ? option : option.name;
              }}
              onChange={(e, data) => {
                field.onChange(data ?? null);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              open={open}
              onOpen={() => !readOnly && setOpen(true)}
              onClose={() => setOpen(false)}
              disableClearable={readOnly}
              filterOptions={(x) => x}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      readOnly,
                      disabled: readOnly,
                    }}
                    label={label}
                    required={isRequired}
                    error={Boolean(errors[controllerProps.name])}
                    helperText={errors[controllerProps.name]?.message}
                  />
                );
              }}
            />
          </>
        )}
      />
    </FormControl>
  );
}
