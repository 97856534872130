import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FormControl, Autocomplete, TextField } from "@mui/material";

interface Props {
  label: string;
  items: any[];
  labelKey: string;
  valueKey: string;
  controllerProps: any;
  errors?: any;
  readOnly?: boolean;
  setProp?: any;
  onChange?: (value: any) => void;
  isRequired?: boolean;
}

export function SelectBoxAutocomplete({
  label,
  items,
  labelKey,
  valueKey,
  controllerProps,
  errors,
  readOnly,
  onChange,
  isRequired,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <FormControl fullWidth>
      <Controller
        {...controllerProps}
        render={({ field }) => (
          <>
            <Autocomplete
              {...field}
              clearOnEscape
              options={items}
              getOptionLabel={(option: any) => option[labelKey]}
              onChange={(e, data) => {
                field.onChange(data ? data[valueKey] : null);
                if (!onChange) return;
                onChange!(data ? data[valueKey] : null);
              }}
              value={
                items.find((x) => x.id === field.value)
                  ? {
                      [valueKey]: field.value,
                      [labelKey]: items.find((x) => x.id === field.value)[
                        labelKey
                      ],
                    }
                  : null
              }
              open={open}
              onOpen={() => !readOnly && setOpen(true)}
              onClose={() => setOpen(false)}
              disableClearable={readOnly}
              renderInput={(params) => {
                return (
                  <TextField
                    required={isRequired}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      readOnly,
                      disabled: readOnly,
                    }}
                    label={label}
                    error={Boolean(errors[controllerProps.name])}
                    helperText={errors[controllerProps.name]?.message}
                  />
                );
              }}
            />
          </>
        )}
      />
    </FormControl>
  );
}
