import { Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { apiUrl } from "../../api/core/apiUrl";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { dictionary } from "../../utils/core/dictionary";
import http from "../../api/core/api";
import { useLocation } from "react-router-dom";
import { BoardContentForm } from "./BoardContentForm";
import { BoardContentInputs } from "../../types/app/boardContent.types";
import { paths } from "../../utils/core/routes";
import { useState } from "react";

export function BoardContentCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [defaultValues] = useState<BoardContentInputs>(state);

  const convertDataFromInputToRequest = (data: BoardContentInputs) => ({
    boardId: data.boardId,
    headline: data.headline,
    positionRow: data.positionRow,
    positionColumn: data.positionColumn,
    urlPreview: data.urlPreview,
    urlDetail: data.urlDetail,
    urlPreviewWidth: data.urlPreviewWidth,
    urlPreviewHeight: data.urlPreviewHeight,
    urlPreviewZoom: data.urlPreviewZoom,
  });

  const handleOnSubmit: SubmitHandler<BoardContentInputs> = async (
    values: BoardContentInputs
  ) => {
    try {
      let data = convertDataFromInputToRequest(values);
      const response = await http.apiPost({
        url: apiUrl.BOARD_CONTENT_POST,
        data: data,
      });
      if (response?.status === 201) {
        enqueueSnackbar(dictionary.BOARD_CONTENT_CREATED, {
          variant: "success",
        });
        navigate(`${paths.BOARD_DETAIL}/${state.boardId}`);
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  return (
    <>
      <PageHeader title={dictionary.BOARD_CREATE} />
      <PageContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={7} lg={7}>
            <Paper>
              <BoardContentForm
                onSubmit={handleOnSubmit}
                onBack={() =>
                  navigate(`${paths.BOARD_DETAIL}/${state.boardId}`)
                }
                defaultValues={defaultValues}
              />
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
