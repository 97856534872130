import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useAuth } from "../../../contexts/authContext";
import { dictionary } from "../../../utils/core/dictionary";
import { paths } from "../../../utils/core/routes";
import { CheckBox } from "../CheckBox/CheckBox";
import { Loader } from "../Loader/Loader";

const theme = createTheme();

type Inputs = {
  username: string;
  password: string;
  persist: boolean;
};

export function LoginForm() {
  const navigate = useNavigate();
  const { signIn, user, isLoading } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<Inputs>();

  useEffect(() => {
    reset({
      username: "",
      password: "",
      persist: false,
    });
  }, [reset]);

  useEffect(() => {
    if (!user) return;
    navigate(paths.HOME);
  }, [user, navigate]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (data.username && data.password)
      signIn!(data.username, data.password, data.persist);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {dictionary.LOG_IN}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label={"Username"}
              {...register("username", { required: "Username required" })}
              error={Boolean(errors.username)}
              helperText={errors.username?.message}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              type="password"
              label={"Password"}
              {...register("password", { required: "Password required" })}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
            />
            <CheckBox
              label={"Persist"}
              controllerProps={{
                name: "persist",
                control: control,
              }}
              errors={errors}
            />
            {isLoading ? (
              <span style={{ marginLeft: "45%" }}>
                <Loader />
              </span>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {dictionary.LOG_IN}
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
