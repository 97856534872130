import AddIcon from "@mui/icons-material/Add";
import { Fab } from "@mui/material";
import { useNavigate } from "react-router";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import { useAuth } from "../../contexts/authContext";
import { UserRoles } from "../../types/core/auth.types";
import { dictionary } from "../../utils/core/dictionary";
import { PermissionList } from "./PermissionList";

export function PermissionPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const isReadOnly = user?.roles.includes(UserRoles.Admin) ? false : true;
  const headerButtons = [
    <Fab
      key={0}
      color="primary"
      aria-label="add"
      size="medium"
      onClick={() => {
        navigate(`/permission/create`);
      }}
    >
      <AddIcon />
    </Fab>,
  ];

  return (
    <>
      <PageHeader
        title={dictionary.PERMISSIONS}
        buttons={isReadOnly ? null : headerButtons}
        xs={6}
      />
      <PageContent>
        <PermissionList isReadOnly={isReadOnly} />
      </PageContent>
    </>
  );
}
