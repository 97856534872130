import React from "react";
import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { routesFullscreen } from "./utils/core/routes";

export function AppContentFullScreen() {
  return (
    <>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <div style={{ marginTop: 10, padding: "0 10px" }}>
        <Routes>
          {routesFullscreen.map(({ path, component }) => {
            return <Route key={path} path={path} element={component} />;
          })}
        </Routes>
      </div>
    </>
  );
}
