import React from 'react';
import { LoginForm } from '../../components/core/LoginForm/LoginForm';

export function LoginPage() {
  return (
    <>
      <LoginForm />
    </>
  );
}
