import { Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { LocalUserForm } from ".";
import { apiUrl } from "../../api/core/apiUrl";
import { Loader } from "../../components/core/Loader/Loader";
import { PageContent } from "../../components/core/PageContent/PageContent";
import { PageHeader } from "../../components/core/PageHeader/PageHeader";
import {
  LocalUser,
  LocalUserInputs,
  LocalUserPasswordInputs,
} from "../../types/app/localUser.types";
import { dictionary } from "../../utils/core/dictionary";
import {
  convertDataFromInputToUpdateRequest,
  convertPassDataFromInputToUpdateRequest,
} from "../../utils/core/functions/localUser.functions";
import { paths } from "../../utils/core/routes";
import http from "../../api/core/api";
import { LocalUserPasswordChangeForm } from "./LocalUserPasswordChangeForm";

export function LocalUserDetail() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState<LocalUserInputs | null>(
    null
  );

  const [localUser, setLocalUser] = useState<LocalUser | null>(null);

  const fetchLocalUser = async () => {
    const response = await http.apiGet({
      url: `${apiUrl.LOCAL_USER_ITEM}?id=${id}`,
    });
    setLocalUser(response?.data);
  };

  useEffect(() => {
    fetchLocalUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!localUser) return;

    setDefaultValues({
      id: localUser.id,
      name: localUser.name,
      email: localUser.email,
      password: localUser.password,
    });
  }, [localUser]);

  const handleOnSubmit: SubmitHandler<LocalUserInputs> = async (
    values: LocalUserInputs
  ) => {
    try {
      let data = convertDataFromInputToUpdateRequest(values);
      const response = await http.apiPut({
        url:
          `${apiUrl.LOCAL_USER_PUT}?id=${data.id}&name=${data.name}` +
          (data.email ? `&email=${data.email}` : ""),
        data: data,
      });
      if (response?.status === 204) {
        enqueueSnackbar(dictionary.LOCAL_USER_EDITED, {
          variant: "success",
        });
        navigate(`${paths.LOCAL_USER}`);
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  const handleOnSubmitPassword: SubmitHandler<LocalUserPasswordInputs> = async (
    values: LocalUserPasswordInputs
  ) => {
    try {
      let data = convertPassDataFromInputToUpdateRequest(values);
      const response = await http.apiPut({
        url: `${
          apiUrl.LOCAL_USER_PASSWORD_PUT
        }?id=${id}&password=${encodeURIComponent(data.password)}`,
        data: {
          ...data,
          id: id,
          password: encodeURIComponent(data.password),
        },
      });
      if (response?.status === 204) {
        enqueueSnackbar(dictionary.LOCAL_USER_PASSWORD_CHANGED, {
          variant: "success",
        });
      }
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };

  if (!defaultValues) return <Loader />;
  return (
    <>
      <PageHeader title={dictionary.LOCAL_USER_DETAIL} />
      <PageContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <Paper>
              <LocalUserForm
                onSubmit={handleOnSubmit}
                onBack={() => navigate(`${paths.LOCAL_USER}`)}
                defaultValues={defaultValues}
                isDetail={true}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Paper>
              <LocalUserPasswordChangeForm onSubmit={handleOnSubmitPassword} />
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
